.google-autosuggestion-list {
  margin-top: 0px;
  width: 100%;
  height: 220px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin-bottom: 108px;
  position: absolute;
  z-index: 1;
  background: #fff;
  padding: 0 10px;
  overflow-y: auto;
  cursor: pointer;
}

.btnenabled {
  color: black;
}

.input-field,
.input-field:hover {
  border: 1px solid #A1A1AA !important;
  height: 45px;
}

.btnenabled1:hover,
.btnenabled1 {
  color: black !important;
  background: #0ff4bd !important;
}

.input-green,
.input-green:hover {
  border: 1px solid #0ff4bd !important;
  background: rgba(15, 244, 189, 0.07) !important;
  height: 45px;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;