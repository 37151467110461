.breadcrumbs li a,
.breadcrumbs li {
  font-weight: bold;
  cursor: pointer;
}

.breadcrumb>.active {
  color: #20222A;
  text-decoration: none;
  cursor: pointer;
}

.breadcrumb li a:hover {
  cursor: pointer;
}

.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  font-weight: 500;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px !important;
  cursor: pointer !important;
  margin-bottom: 15px;

}

.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer !important;
}

footer {

  position: fixed;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 40px;
  background: grey;

}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;