.privacymodal{
.ant-modal-content{
  height:100%;
  .ant-modal-body
  {
    height:100%;
  .privacypolicy
  {
    border:2px solid #0000001A;
    height:100%;
    border-radius: 10px;
    position: relative;
    width: 70%;
    margin: auto;
    overflow: scroll;
    .crossimg{
      position: absolute;
      right:2rem;
      top: 2rem;
      cursor:pointer;
    }
    .heading{
      padding:30px 10px;
      border-bottom: 10px solid black;
      color:#20222A;
      font-weight: 500;
      font-size: 25px;
    }
   
    .content{
      // overflow: scroll;
      padding:30px 10px;
      .question{
        color:#20222A;
        font-weight: 500;
        font-size: 15px;
      }
      .solution{
        color:#9E9E9E;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
}
}
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;