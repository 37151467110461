.navbar-container {
  height: 70px;
  .navbar-wrapper {
    .heading {
      font-size: 25px;
      color: black;
      font-weight: 500;
    }
  }
  .navbar-data {
    .infodata {
      padding: 10px;
      background-color: #F6F6FB;
      max-width: 350px;
      min-width: 120px;
      border-radius: 10px;
      margin: 10px;
      display: flex;
    }
    .infodataclient {
      max-width: 300px;
      min-width: 90px;
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;