.main_containerhome {
  .aboutusinfo {
    padding-left: 3rem;

    .aboutusinfo1 {
      background-color: black;
      width: calc(100vw - 3rem);
      height: 100vh;
      display: flex;
      padding-left: 10px;

      .aboutusinfo1left {
        width: 40vw;
        width: 42%;
        height: 90vh;
        padding: 4rem 7rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .aboutusinfo1right {
        flex: 1;
        width: 50%;
        display: flex;
        flex-direction: column;
        color: #fff;
        padding: 30px;
        position: relative;

        .aboutusimg2 {
          position: absolute;
          top: 58%;
        }

        .aboutusheading {
          font-size: 60px;
        }

        .aboutusheadinginfo {
          color: rgba(214, 214, 214, 1);
          line-height: 30px;
        }

        .explorebtn {
          align-items: flex-start;
          display: flex;
          width: 100%;
          padding-top: 10px;
          button {
            padding: 6px;
            height: 47px;
            width: 22%;
            font-size: 17px;
            background-color: #0ff4bd;
            color: black;
            border-radius: 10px
          }
        }
      }
    }

    .aboutusinfo2 {
      height: 32vh;
      display: flex;
      padding-left: 49px;
      width: 40vw;
      justify-content: flex-start;

      .aboutusper {
        display: flex;
        flex-direction: column;
        width: 259px;
        padding: 30px 15px 30px 0px;

        .aboutuspercount {
          font-size: 63px;
          font-weight: 500;

          span {
            color: rgba(15, 244, 189, 1);
          }
        }

        .aboutusperheading {
          font-size: 20px;
          color: rgba(80, 81, 80, 1);

        }
      }
    }

    .aboutusinfo3 {
      display: flex;
      max-height: 35vh;
      padding: 4rem 1rem;
      margin: 20px 0px;
      .leftcontent {
        width: 46%;
        padding: 0px 20px;
        color: black;
        font-size: 35px;
        //font-weight: 500;
      }

      .rightcontent {
        width: 44%;
        padding: 35px 10px;
        color: rgba(80, 81, 80, 1);
        font-size: 18px;
      }
    }

    .aboutusinfo4 {
      display: flex;
      margin: 10vh 1rem;
      justify-content: space-between;

      .aboutinfocard {
        flex: 1;
        padding: 10px;

        .aboutusinfocontent {
          color: rgba(161, 161, 170, 1) !important;
          width: 80%;
        }
      }
    }

    .aboutusinfo6 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 100%;
      margin: 30vh 0px;

      .image-container {
        position: relative;

        .main-image {
          width: 90vw;
          height: 95vh;
          object-fit: cover;
        }

        .top-left-card {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          margin-top: -100px;
          background-color: #fafbfc;
          height: 46vh;
          width: 37vw;
          border-radius: 15px;

          img {
            border-radius: 50%;
          }

          .ant-card {
            height: 100%;
          }

          .ant-card-body {
            background-color: rgba(250, 251, 252, 1);
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 4rem;

            p {
              color: rgba(80, 81, 80, 1);
              font-size: 16px;
            }

            .joinbtn,
            .joinbtn:hover {
              height: 60px;
              background-color: #0ff4bd;
              color: black;
              font-weight: 600;
              border: transparent;
              width: 29%;
              border-radius: 13px;
              padding: 10px;
            }
          }
        }

        .bottom-right-card {
          position: absolute;
          bottom: -8rem;
          right: 0;
          z-index: 1;
          width: 40vw;
          height: 46vh;
          border-radius: 15px;
          .ant-card {
            height: 100%;
          }

          .ant-card-body {
            background-color: rgba(250, 251, 252, 1);
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 4rem;
            p {
              color: rgba(80, 81, 80, 1);
              font-size: 16px;
            }

          }
        }
      }
    }
    .aboutusinfo7 {
      width: 100vw;
      overflow: auto;
      height: 500px;
      .img1 {
        padding-right: 20px;
        padding-left: 30px;
        border-radius: 10px;
       // width: 600px;
        img {
          width: 550px;
          height: 100%;
          border-radius: 10px;
        }
      }
      .img2 {
        padding-right: 20px;
       // width: 600px;
        img {
          width: 550px;
          height: 100%;
          border-radius: 10px;
        }
      }
      .img3 {
        padding-right: 20px;
      //  width: 600px;
        img {
          width: 550px;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }
}

@media(max-width:1400px) {
  .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheading {
    font-size: 48px;
  }


  .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .explorebtn {
    padding: 0px !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1left {
    padding: 2rem 4rem !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheadinginfo {
    font-size: 15px !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo6 .image-container .bottom-right-card {
    height: 53vh !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo1 {
    height: 115vh;
  }

  .main_containerhome .aboutusinfo .aboutusinfo6 .image-container .top-left-card {
    height: 58vh !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo4 .aboutinfocard .aboutusinfocontent {
    width: 90% !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo3 .rightcontent {
    width: 50% !important;
  }

  //padding: 2rem 4rem;
}

@media(min-width:1400px)
{
  .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusimg2 {
    position: absolute;
    top: 60%;
}
.main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheadinginfo {
    //color: #d6d6d6;
    line-height: 26px;
}
}
@media only screen and (max-width: 1350px) and (min-width: 1100px){
 .joinbtn:hover,.joinbtn {
  width: 40% !important;
 }
}
@media(max-height:650px)
{
 .processpage .processpage2 {
    height: 116vh !important;
 }
}
@media(max-width :1024px) {
  .main_containerhome .aboutusinfo .aboutusinfo6 .image-container .top-left-card {
    height: 36vh !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo6 .image-container .bottom-right-card {
    height: 36vh !important;
  }

  .aboutusinfo6 {
    margin: 10vh 0px !important;
  }

  .aboutusinfo4 {
    margin: 1vh 0px 2vh 0px !important;
  }

  .aboutusinfo2 {
    height: 21vh !important;
  }

  .aboutusinfo6 .image-container .top-left-card .ant-card-body .joinbtn {
    width: 56% !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheading {
    font-size: 43px;
  }

  .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .explorebtn {
    padding: 15px !important;
  }

  .aboutusinfo1 {
    .aboutusinfo1left {
      width: 40vw !important;
      width: 48% !important;
      padding: 0px !important;
      /* padding-right: 117px; */
      /* padding-bottom: 50px; */
      /* padding-top: 29px; */
      /* padding-left: 100px; */
      height: 53vh !important;
    }

    .aboutusinfo1right {
      .explorebtn button {
        width: 62% !important;
      }

      .aboutusimg2 {
        right: 1rem !important;
      }
    }
  }
}

@media(max-width:820px) {
  .main_containerhome .aboutusinfo .aboutusinfo6 .image-container .top-left-card {
    height: 43vh !important;
    width: 52vw;
  }

  .main_containerhome .aboutusinfo .aboutusinfo6 .image-container .bottom-right-card {
    height: 43vh !important;
    width: 52vw;
  }

  .main_containerhome .aboutusinfo .aboutusinfo1 {
    height: 115vh;
  }

  .joinbtn:hover,
  .joinbtn {
    width: 40% !important;
  }

  .main_containerhome .aboutusinfo .aboutusinfo3 {
    height: 33vh !important;
  }
}

@media(max-width:800px) {
  .main_containerhome .aboutusinfo .aboutusinfo1 {
    height: 124vh;
  }
}
@media(max-width:1350px)
{
    .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheading {
      font-size: 30px !important;
    }
    .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheadinginfo {
      line-height: 34px !important;
    }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;