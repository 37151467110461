.card-element-wrapper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .pay-amount {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background: rgba(0, 85, 134, 0.1);
    border-radius: 15px;

    #text {
      font-weight: 700;
      font-size: 18px;
      color: #005586;
    }

    #amount {
      font-weight: 700;
      font-size: 24px;
      color: #005586;
    }
  }

  .input-text-field {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #111111;
    padding: 0 1.25rem;
    border: 1px solid #A1A1AA;
  }

  .input-text-field1 {
    background: rgba(15, 244, 189, 0.07) !important;
    border: 1px solid #0FF4BD !important;
  }

  .card-number {
    width: 100%;
    height: 60px;

    .InputContainer input {
      font-weight: 500;
      font-size: 16px !important;
      color: #111111;
    }
  }

  .card-number1,
  .card-expiry1 .card-cvv1 {
    border: 1px solid #0FF4BD;
    background: rgba(15, 244, 189, 0.07) !important;
    border-radius: 16px;
    padding: 19px 16px;
  }

  .card-number,
  .card-expiry,
  .card-cvv {
    border: 1px solid #A1A1AA;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 19px 16px;
  }

  .CardBrandIcon-container {
    right: 0;
  }

  .label {
    font-family: 'Poppins', sans-serif;
    color: #111111;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

}

.pay-now,
.pay-now:hover {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #000 !important;
  text-align: center;
  width: 50%;
  height: 50px;
  padding: 13px;
  margin: auto;
  background: #0ff4bd;
  box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
  border-color: transparent !important;
}

.pay-now-disabled,
.pay-now-disabled:hover {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  width: 50%;
  height: 50px;
  padding: 13px;
  background: #50515080 !important;
  color: #000000 !important;
  font-weight: 700;
  box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
  border-radius: 10px;
  cursor: disabled;
  opacity: 0.4;
  margin: auto;
  justify-content: center;
}

.skipcard {
  color: #000;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  opacity: 0.4;
  margin-top: 1rem;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;