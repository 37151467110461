.signup_container {
  max-width: 100%;
  margin: auto;
  min-height: 100vh;
// .ant-input:hover,.ant-input:focus {
//       border:1px solid #0FF4BD !important;
//     }
  .row {
    // width: 100%;
    display: flex;
    flex-direction: row;
    .signup_left_panel {
      // width:70%;
      height:100vh;
      .image-wrapper{
        position: relative;
        .content-img{
          position: absolute;
        }
      }
      .splashimgstyle {
        width: 100% !important;
        height: 100% !important;
      }
      .ant-carousel {
        .slick-dots li button {
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: #D1D5DB;
        }
  
        .slick-dots li.slick-active button {
          width: 30px;
          height: 8px;
          border-radius: 10px;
          background:  #0FF4BD;
        }
      }
    }
    .signup_right_panel {
      height: 100vh;
      // width:30%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:auto;
      .rightpanelcontent {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        // gap: 3.25rem;
        .signup-logo
        {
          display: flex;
          justify-content: center;
          width:200px;
          margin-bottom: 2rem;
        }
        .signup_heading {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .signup_data {
            .signup_header {
              color: #000;
              text-align: center;
              font-family: "Poppins",sans-serif;
              font-size: 1.875rem;
              font-style: normal;
              font-weight: 600;
              line-height: 2.5rem;
              gap: 2.5rem;
            }
            .signup_para {
              color: #20222a;
              text-align: center;
              font-family: "Poppins",sans-serif;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          .signup_end {
            display: flex;
            flex-direction: column;
            gap: 1.75rem;
            align-items: center;
            .sign_in_text {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
            }
            .form_data {
              display: flex;
              flex-direction: column;
              gap: 1.25rem;
              .s-form{text-align: center;}
              .ant-form-item-control-input-content {
                .ant-input {
                  background: transparent;
                  color: var(--black, #20222a);
                  font-family: "Poppins",sans-serif;
                  font-size: 1.0625rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%; /* 1.59375rem */
                }
              }
              .input_field {
                // width: 25rem;
                // height: 4.375rem;
                width:80% ;
                height:60px;
                color: #20222a;
                font-family: "Poppins",sans-serif;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                border-radius: 0.9375rem;
                border: 1px solid #a1a1aa;
                background: #fff;
              }
              .validated_input_field {
                width:80%;
                height:60px;
                font-family: "Poppins",sans-serif;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                color: #20222a;
                border-radius: 0.9375rem;
                border: 1px solid #0ff4bd;
                background: transparent;

                background: rgba(15, 244, 189, 0.07);
              }
              .invalid_input_field {
                width:80%;
                height:60px;
                font-family: "Poppins",sans-serif;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                color: #20222a;
                border-radius: 0.9375rem;
                border: 1px solid red;
                background: rgba(239, 94, 147, 0.07);
              }

              .password_strength_content {
                width: 80%;
                margin: auto;
                display: flex;
                flex-direction: column;
                gap: 0.56rem;
                .password_strength_title {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;

                  .password_strength {
                    color: #293d2f;
                    font-family: "Poppins",sans-serif;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                    margin-right: 10px;
                     /* 12px */
                  }
                  .password_strength_status {
                    color: #37e17f;
                    font-family: "Poppins",sans-serif;
                    font-size: 0.625rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                  }
                  .password_strength_percentage {
                    color: #293d2f;
                    text-align: right;
                    font-family: "Poppins",sans-serif;
                    font-size: 0.625rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%; /* 0.75rem */
                  }
                }
              }

              .ant-progres {
                .ant-progress-steps-item-active {
                  border-radius: 5px !important;
                }
              }
              .signup_input_icon {
                width: 1.5rem;
                height: 1.5rem;
                flex-shrink: 0;
                margin: 0.3rem;
              }
              .ant-form-item {
                .ant-input-affix-wrapper {
                  .ant-input-affix-wrapper-lg {
                    .ant-input {
                      .ant-input-lg {
                        background-color: red !important;
                      }
                    }
                  }
                }
              }
              .login_form_button_disabled {
                display: flex;
                margin:auto;
                width: 80%;
                height:50px;
                // height: 3.75rem;
                padding: 0.625rem 8.375rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 0.9375rem;
                background: #e2e2e7;
                color: rgba(80, 81, 80, 0.5);
                text-align: center;
                font-family: "Poppins",sans-serif;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 600;
                line-height: 2.5rem; /* 235.294% */
                pointer-events: none;
              }
              .login_form_button {
                height:50px;
                display: flex;
              //  justify-content: center;
                //align-items: center;
                margin:auto;
                width: 80%;
                padding: 0.625rem 8.375rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 0.9375rem;
                background: #0ff4bd;
                color: #20222a;
                text-align: center;
                font-family: "Poppins",sans-serif;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 600;
                line-height: 2.5rem;

              }

              .login_form_button:hover {
                color: rgba(80, 81, 80, 1);
                border: rgba(80, 81, 80, 0.5);
              }
              .signup-checkboxd
              {
                width:80%;
                margin: auto;
              }
              .checkbox_text {
                color: #9e9e9e;
                font-family: "Poppins",sans-serif;
                font-size: 0.8125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem; /* 184.615% */
                margin-left: 0px;
                margin-right: -8px;
              }
            }
          }
        }
      }
    }
  }
}
.splashimgstyle {
  height: 100vh;
  // margin:2vh 0;
  width: 100%;
  // need to remove
 // object-fit: cover;
}
.ant-carousel {
  .slick-dots-bottom
  {
    right:24rem;
    bottom:2rem;
  }
  .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: #fff;
  }

  .slick-dots li.slick-active button {
    width: 30px;
    height: 8px;
    border-radius: 10px;
    background:  #0FF4BD;
  }
}
.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
  width: 300px;
 margin:auto;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover ,.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus{
  // .ant-input:hover,.ant-input:focus {
    border:1px solid #0FF4BD !important;
  
}
.ant-input-affix-wrapper:hover,.ant-input-affix-wrapper:focus {
  // .ant-input:hover,.ant-input:focus {
    border:1px solid #0FF4BD !important;
    box-shadow: none !important;
  
}
@media(max-width:1400px)
{
 
  .signup_container .row .signup_right_panel .rightpanelcontent .signup_heading .signup_end {
    gap:10px;
  }
  .signup_container .row .signup_right_panel .rightpanelcontent .signup_heading .signup_end .form_data .input_field {
    height:45px;
  }
  .signup_container .row .signup_right_panel .rightpanelcontent .signup-logo {
    margin:0px;
  }
  .signup_container .row .signup_right_panel {
    margin:0;
  }
  .signup_container .row .signup_right_panel .rightpanelcontent .signup_heading {
    gap:0;
  }
}
@media(max-width:1050px)
{
  .signup_container {
    overflow-y: scroll !important;
    margin:5rem auto;

  .row {
    // width: 100%;
    display: flex;
    flex-direction: column-reverse;
  padding:3rem;
 
    .left-content
    {
      display:none !important;
    }
  
  }
  
  .splashimgstyle{
    object-fit: cover;
    width: 300px;
    height: 398px;
    margin: auto;
  }
  .slick-dots-bottom {
     right: 2rem; 
     bottom: 2rem; 
}
  
}
.signindata{
  width:80% !important;
}

}
@media only screen and (max-width: 1100px) and (min-width: 1000px)  {
  .signup_container{
    width:100% !important;
    margin:auto;
    .row{
      display:flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media (max-width: 1100px) and (min-width:1000px)
{
.signup_container .row .signup_right_panel {
   margin-top: 20% !important;
}
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;