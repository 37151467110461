.jobsdashboard {
  .jobs-tab {
    .joblists {
      height: calc(100vh - 180px);
      overflow-y: auto;
    }

    .joblistongoing {
      height: calc(100vh - 300px);
      overflow-y: auto;
    }

    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .ant-tabs-nav {
      margin-bottom: 10px;
      border: 1px solid #F5F5F5;
      width: fit-content;
      //  padding: 10px;
      border-radius: 5px;
      height: 52px;

      &:before {
        display: none;
      }
    }

    .ant-tabs-ink-bar {
      background-color: transparent;
    }

    .ant-tabs-tab {
      background: transparent;
      width: 125px;
      height: 50px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-tabs-tab-btn {
        font-size: 16px;
        color: #849290;
        font-weight: 600;
      }

      &.ant-tabs-tab-active {
        background: black;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-tabs-tab-btn {
          color: #ffffff;
        }
      }
    }

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0 0 0 15px;
    }

    .singlejobs {
      background-color: #F4F7FF;
      cursor: pointer;
      width: 82vw;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;

      .row1 {
        display: flex;
        justify-content: space-between;

        .para1 {
          .rowfirst {
            color: #505150;
            font-size: 16px;
            font-weight: 500;
          }

          .services {
            color: #A1A1AA;
          }

          .rowsecond {
            padding: 10px;
            background: #ECECEE;
            border-radius: 25px;
            color: #20222A;
            font-weight: 500;
            gap: 15px;
            text-align: center;
          }

          .rowsecondname {
            color: #0D0D0D;
            font-weight: 500;
            font-size: 16px;
          }
        }

        .para2 {
          .rowfirst {
            color: #505150;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
          }

          .rowsecond {
            height: 30px;
            width: 120px;
            padding: 5px;
            background: #FFD912;
            border-radius: 25px;
            color: #20222A;
            font-weight: 500;
            gap: 15px;
            text-align: center;
            float: right;
          }

          .rowsecond1 {
            background-color: #0FF4BD !important;
          }

          .rowpayment {
            background-color: #FF8D8D;
            width: 140px !important;
          }

          .rowsecond2 {
            background-color: #44EF8C !important;
          }
        }
      }

      .locationinfo {
        color: #0D0D0D;
        font-weight: 500;
        font-size: 16px;
      }

      .calenderimage {
        border-left: 2px solid grey;
        margin-left: 5px;
        margin-right: 5px;
        padding: 0px 5px;
      }

      .clockimage {
        border-left: 2px solid grey;
        margin-left: 5px;
        margin-right: 5px;
        padding: 0px 5px;
      }
    }
  }

  .slider {
    .slick-slider {
      .slick-prev {
        visibility: hidden !important;
      }
    }

  }

  .upcomingjobs {
    width: 95%;
    height: 110px;
    background: #20222A;
    color: #ffff;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;

    .para1 {
      .rowfirst {
        color: #0FF4BD;
        font-weight: 400;
      }

      .rowsecond1 {
        font-size: 14px;
      }
    }

    .para2 {
      .rowsecond {
        padding: 10px;
        border-radius: 15px;
        background-color: #0FF4BD1A;
        color: #0FF4BD;
        width: 100px;
        float: right;
        text-align: center;
      }
    }
  }
}

.slick-slide img {
  display: flex !important;
}

.slick-slider {
  width: 97% !important;

  .slick-next:before,
  .slick-prev:before {
    width: 20px !important;
    height: 33px !important;
    color: #0D0D0D !important;
    //background-color: #0D0D0D;
  }
}

.contractorslist {
  width: 96%;
  overflow-x: scroll;
  margin: 10px 10px 10px 2px;
  display: flex;
  gap: 15px;

  .singlecontractor {
    // border-radius: 16px;
    background: var(--6, linear-gradient(103deg, #20222A 4.59%, #425156 96.71%));
    width: 180px;
    height: 220px;
    border-radius: 15px;
    color: #F4F7FF;
    text-align: center;
    padding: 16px 8px 8px 8px;
    cursor: pointer;

    .userimg {
      height: 60px;
      width: 60px;
      margin: auto;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }

    .ratings {
      .ratingnumber {
        color: #FFD912;
      }

      .ratingnumber1 {
        font-size: 12px;
        display: flex;
        align-items: center;

      }
    }

    .messagebtn {
      background-color: #0FF4BD;
      color: #20222A;
      font-weight: 500;
      width: 120px;
      margin: 5px;
      margin: 10px auto;
      border-radius: 18px;
      padding: 5px 0px;
      height: 36px;
      cursor: pointer;
      font-size: 16px;
    }

    .declinebtn {
      font-weight: 300;
      cursor: pointer;
    }
  }
}

.deletejobbtn {
  margin: 20px 10px;
  padding: 17px;
  background: #F6F6FB;
  width: 250px;
  text-align: center;
  border-radius: 15px;
  font-weight: 800;
  color: #0D0D0D;
  cursor: pointer;
}

.upcomingjobbtn {

  .cancelbtn,
  .cancelbtn:hover {
    margin: 20px 10px;
    height: 45px;
    background: #F6F6FB;
    width: 150px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    color: #0D0D0D !important;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }


  .messagebtn,
  .messagebtn:hover {
    margin: 20px 10px;
    height: 45px;
    background: #0FF4BD;
    width: 150px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #0D0D0D !important;
    border: none;
    cursor: pointer;
  }

  .messagebtnd,
  .messagebtnd:hover {
    background-color: #E2E2E7 !important;
    color: #50515080 !important;
    cursor: not-allowed !important;
  }

  .makepaymentbtn,
  .makepaymentbtn:hover {
    background-color: #E2E2E7 !important;
    color: #50515080 !important;
    font-weight: 500;
    cursor: not-allowed;
  }

  .makepaymenten,
  .makepaymenten:hover {
    background-color: #0FF4BD !important;
    color: #0D0D0D !important;
    cursor: pointer !important;
  }
}

.popup {
  .crossicon {
    position: absolute;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;

    .profileimg {
      width: 120px;
      height: 120px;
      margin: 10px auto;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .profilename {
      color: #0D0D0D;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .descriptionheading {
    color: #0D0D0D;
    font-size: 20px;
    font-weight: 500;
  }

  .description {
    color: #A1A1AA;
    font-size: 14px;
    height: 130px;
    overflow-y: scroll;
  }

  .ratings {
    height: 50px;
    padding: 10px;
    border-bottom: 1px solid #FF9F29;
    border-radius: 12px;
    width: 200px;
    justify-content: center;

    .starimg {
      color: #FF9F29;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .btns {
    gap: 15px;
    height: 50px;

    .declinebtn {
      color: #DB524E;
      background-color: #F6F6FB;
      width: 120px;
      padding: 15px;
      text-align: center;
      border-radius: 15px;
      cursor: pointer;
      font-weight: 600;
    }

    .messagebtn {
      color: #20222A;
      background-color: #F6F6FB;
      width: 120px;
      padding: 12px;
      text-align: center;
      border-radius: 15px;
      border: 1px solid #0FF4BD;
      font-weight: 600;
      cursor: pointer;
    }

    .acceptbtn {
      background-color: #0FF4BD;
      width: 120px;
      padding: 15px;
      text-align: center;
      border-radius: 15px;
      color: #20222A;
      font-weight: 500;
      cursor: pointer
    }
  }
}

.editsavebtn,
.editsavebtn:hover {

  button,
  button:hover {
    width: 20%;
    color: #0D0D0D !important;
    font-weight: 600;
    background-color: #0FF4BD;
    height: 45px;
    font-size: 14px;
    border: transparent !important;
    cursor: pointer;
  }
}

.editsavebtnd {
  button {
    width: 20%;
    color: #0D0D0D;
    font-weight: 600;
    background-color: #F4F7FF;
    height: 45px;
    font-size: 14px;
  }
}

.contractorinfo {
  height: 85px;
  margin-bottom: 10px;

  .contractorimage {
    img {
      height: 85px;
      width: 85px;
      border-radius: 5px;
    }
  }
}

.ratingmodal {
  .crossImage {
    display: flex;
    justify-content: center;
  }

  .crossicon {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 2rem;
    top: 1rem;
    cursor: pointer;
  }

  .logoutdesc {
    display: flex;
    justify-content: center;
    color: #0D0D0D;
    font-weight: 500;
    font-size: 18px;
  }

  .confirmButtons {
    justify-content: space-between;
    display: flex;
    height: 50px;
    margin-bottom: 20px 0 0 10px;

    .yesbtn {
      width: 38%;
      height: 100%;
      background-color: #E7E8EA;
      padding: 8px 12px;
      border-radius: 15px;
      outline: none;
      border-color: #E7E8EA;
      font-size: 16px;
      color: black !important;
      font-weight: bold;
      border: transparent !important;
    }

    .nobtn,
    .nobtn:hover {
      width: 55%;
      height: 100%;
      margin-left: 5px;
      background-color: #0FF4BD;
      color: black;
      font-weight: bold;
      padding: 8px 15px;
      border-radius: 15px;
      font-size: 16px;
      border: transparent !important;
    }

    .nobtnd,
    .nobtnd:hover {
      background-color: #E2E2E7;
      color: #50515080;
      font-weight: 500;
    }
  }

  .ant-modal-content {
    width: 350px;
    padding: 24px 46px;
  }
}

.paymentinfo {
  .paymentheading {
    color: #0D0D0D;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
  }

  .timeinfo {
    color: #505150;
    font-size: 15px;
    margin-bottom: 20px;
  }

  .transactioninfo {
    background-color: #F4F7FF;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;

    .transactiondetails {
      font-size: 16px;
      margin: 10px;

      .trasactionheading {
        color: #505150;
      }

      .trasactionheading1 {
        color: #0F1E23;
        font-weight: 600;
      }
    }

    .totalamount {
      background-color: #ffffff;
      height: 50px;
      padding: 20px;
      border-radius: 10px;
      align-items: center;
      font-size: 16px;

      .totalamount1 {
        color: #0F1E23;
        font-weight: 600;
      }

      .price {
        color: #0FF4BD;
        font-weight: 600;
      }
    }
  }
}

.allexpenses {
  height: 118px;
  overflow-y: scroll;
  margin-bottom: 20px;

  .expenses {
    padding: 12px;
    border: 1px solid #8492901A;
    border-radius: 4px;

    .expenseapp {
      color: #0FF4BD;
      font-family: 500;
      font-size: 16px;
    }
  }
}

.makepaymentpage {
  .paymentinfo {
    color: #A1A1AA;
    font-size: 14px;
    width: 65%;
    line-height: 25px;
  }

  .expensesandcard {
    overflow-y: scroll;
    height: calc(100vh - 250px);

    .expenseinfo {
      flex: 2;

      .expensename {
        font-size: 16px;
        color: #505150;
        font-weight: 500;
      }

      .expenses {
        width: 89%;
      }

      .paymentinfo {
        width: 89%;
      }

      .rejected {
        background-color: #F6F6FB;
        color: #0D0D0D;
        width: 130px;
        border: none;
        height: 45px;
        font-weight: 500;
      }

      .expenseapprove {
        background-color: #0FF4BD;
        color: #0D0D0D;
        width: 130px;
        border: none;
        height: 45px;
        font-weight: 500;
      }
    }

    .cardheading {
      .cardheader {
        color: #0D0D0D;
        font-size: 16px;
      }

      .addcard {
        color: #0FF4BD;
        font-size: 16px;
      }
    }

    .cards {
      .card {
        padding: 10px;
      }

      .num {
        font-size: 18px;

        .num2 {
          width: 6px !important;
          height: 6px !important;
          background-color: #0D0D0D;
          border-radius: 50%;
          margin-left: 5px;
          margin-top: 8px;
        }

        .num3 {
          margin-right: 10px;
        }
      }

      .num1 {
        font-size: 15px;
      }
    }

    .paybtn {
      width: 75%;
      height: 50px;
      margin: 20px;
      border-radius: 10px;
      background-color: #E2E2E7;
      color: #50515080;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
    }

    .paybtnd {
      background-color: #E2E2E7;
    }

    .paybtnen {
      background-color: #0FF4BD;
      color: #0D0D0D;
    }

    .cardinfo {
      flex: 2;
      display: flex;

      .cards {
        .cardheading {
          margin-bottom: 15px;

          .cardh1 {
            color: rgba(80, 81, 80, 1);
            font-size: 18px;
          }

          .cardh2 {
            color: rgba(15, 244, 189, 1);
            font-size: 18px;
            cursor: pointer;
          }
        }
      }

      //align-items: center;
    }
  }
}

.purchasebtnd,
.purchasebtnd:hover {
  width: 100%;
  height: 45px;
  border: transparent;
  font-size: 16px;
  background-color: rgba(226, 226, 231, 1) !important;
}

.purchasebtn,
.purchasebtn:hover {
  width: 100%;
  height: 45px;
  border: transparent;
  font-size: 16px;
  color: black !important;
  background-color: #0FF4BD;
  font-weight: 500;
}

.paymentsucesspage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ackbtn,
  .ackbtn:hover {
    height: 50px;
    background-color: #0FF4BD !important;
    color: #0D0D0D !important;
    font-weight: 500;
    font-size: 18px;
    border: transparent !important;
  }
}

.paymentpage {
  border: 1px solid #F4F7FF;
  background-color: #F4F7FF;
  padding: 20px;
  border-radius: 5px;
}

.aprrovedtxt {
  color: #0FF4BD;
}

.rejectedtxt {
  color: red;
}

.paymentinfopopup {
  .paymentinfo {
    .paymentheading {
      font-size: 22px !important;
      color: black;
      font-weight: 500;
    }

    .paymenttxt {
      font-size: 17px;
      color: rgba(161, 161, 170, 1)
    }

    .ackbtn,
    .ackbtn:hover {
      height: 55px;
      width: 250px;
      background-color: #0FF4BD;
      color: black;
      font-weight: 600;
      margin: 2rem auto 1rem auto;
      border: transparent !important;
      font-size: 18px;
      border-radius: 17px;
    }
  }
}

// @media(max-width:800px)
// {
//   .jobsdashboard .jobs-tab .singlejobs {
//     width: 78vw !important;
// }
// }
@media(max-width:1024px) {
  .makepaymentpage .expensesandcard {
    flex-direction: column;
    overflow-y: scroll;

    .expenseinfo {
      flex: 0 !important;
    }

    .cardinfo {
      flex: 0 !important;
    }
  }

  .jobsdashboard .jobs-tab .singlejobs {
    width: 88vw !important;
  }

  .slick-list {
    width: 92%;
  }

  .slick-slider {
    width: 110% !important;
  }

  .slick-next {
    width: 21% !important;
  }
}

.ant-switch-checked {
  background: #0FF4BD;
}

.addcardmodal .ant-modal-content {
  width: 615px;
  height: 400px;
  top: -1px;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0FF4BD !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background: #0FF4BD;
  border: transparent;
}

.ant-radio-checked:hover {
  background: #0FF4BD !important;
}

.ant-radio-inner:hover {
  border-color: #0FF4BD !important;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;