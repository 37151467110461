.createjob {
  .userinfo {
    display: flex;
    flex-direction: column;
    height: 80px;
    .userinfo1{
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .userinfo2 {
      .userheadingbtn {
        z-index: 111;
        .buttonbtn {
          height: 40px;
          background: #E2E2E7;
          width: 154px;
          color: #50515080;
          padding: 7px 20px;
          top: -10px;
          border-radius: 12px;
        }
        .buttonbtnd {
          height: 40px;
          background: #0ff4bd;
          width: 154px;
          color: black;
          padding: 7px 20px;
          top: -10px;
          border-radius: 12px;
        }
        button:hover {
          border-color: #0ff4bd;
        }
      }
    }
  }
  .jobinfo {
    height: calc(100vh - 185px);
    overflow: scroll;
    width: 100%;
  }
}
.ackpopup {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;

  .crossicon {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 2rem;
    top: 0;

  }

  .termsheading {
    color: black;
    font-size: 18px;
    font-weight: 600;
  }

  .termscontent {
    color: #505150;
    font-weight: 400;
    font-size: 15px;

    .impheading {
      color: black;
      font-weight: 500;
    }
  }
 

  .termscontentbtn,
  .termscontentbtn:hover {
    height: 55px;
    color: black !important;
    border: 1px solid #0ff4bd !important;
    background-color: #0ff4bd;
    font-weight: 500;
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
  }

  .costimgcontent {
    //width:80%;
    height: 220px;
    background-color: #EEFEFA;
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 10px;

    .costimg {
      position: relative;
      top: 30px;
    }

    .approxcost {
      position: relative;
      top: 40px;
      font-size: 20px;
      color: #505150;
      font-weight: 500;
    }

    .cost {
      position: relative;
      top: 47px;
      color: #19B792;
      font-size: 28px;
      font-weight: 600;
    }

    .content {
      margin: auto;
    }
  }


}
.sucesspopup{
  .ant-modal-content{
    width:460px !important
  }
}
.approxbtns{
  width:100%;
  display: flex;
  justify-content: space-between;
  .approxbtn1{
    height: 55px;
  color: black !important;
  border: 1px solid #0ff4bd !important;
//  background-color: #0ff4bd;
  font-weight: 500;
  border-radius: 10px;
  font-size: 17px;
  cursor: pointer;
  width:45%;
  }
  .approxbtn2{
    height: 55px;
    color: black !important;
    border: 1px solid #0ff4bd !important;
    background-color: #0ff4bd !important;
    font-weight: 500;
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
    width:45%;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;