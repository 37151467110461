.timemodal{
  width:150px;
  height:300px;
  .ant-modal-content{
    width:370px;
    height:100%;
    img{
      position: absolute;
     top:-0.5rem;
     right:0;
     width:30px;
     height:30px;
    }
    .serviceheading
    {
     font-size: 20px;
     color:black;
     font-weight: 500;
    }
    .timmings{
      display: flex;
     flex-wrap: wrap;
     padding:10px;
     gap:6px;
     .timming{
      cursor:pointer;
      padding:10px;
      width:60px;
      height:43px;
      background-color:  #F6F6FB;
      border-radius: 6px;
      color:#20222A;
      font-weight: 400;

     
     }
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;