.client-select-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .center_container {
    width: 69%;
    height: 95%;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.94rem;
    padding: 1rem;

    .content-data {
      width: 80%;

      .heading {
        margin: auto;
        text-align: center;
        margin-bottom: 2.5%;
        color: black;
        font-weight: 700;
        font-size: 24px;
      }
    }

    .progress_container {
      width: 65%;
      // max-width: 480px;
      // min-width: 400px;
      margin: auto;
      margin-bottom: 5%;
      margin-top: 2%;
      position: relative;
      top: 10%;


    }

    .num_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 446px;
      height: 429px;
      gap: 40px;

      .para_container {
        width: 25.125rem;
        color: var(--2, #505150);
        // text-align: center;
        display: flex;
        flex-direction: column;
        // align-items: center;
        text-align: center;

        /* 16_Body_1 */
        font-family: "Poppins",sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;

        /* 187.5% */
        p {
          margin-bottom: 0rem;
        }
      }

      .num_field {
        width: 25rem;
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;

        color: rgba(161, 161, 170, 0.4);
        font-family: 'Poppins',sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 1.59375rem */
      }

      .select_field {
        // width: 25rem;
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;

        color: rgba(161, 161, 170, 0.4);
        font-family: 'Poppins',sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 1.59375rem */
      }

      .input-container {
        position: relative;

        .ant-input {
          margin-left: 2.44rem;
          font-family: 'Poppins',sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          /* 1.59375rem */
          padding-top: 1%;
        }

        .ant-select-selector {
          .ant-select-selection-item {
            color: var(--black, #20222a);
            font-family: 'Poppins',sans-serif;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 1.59375rem */
          }
        }
      }

      .prefix {
        position: absolute;
        top: 33%;
        left: 30px;
        z-index: 10;
        width: 5.5rem;
        height: 4.38rem;

        .country_code {
          color: var(--black, #20222a);
          font-family: 'Poppins',sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }

      .input-element {
        padding-left: 80px;
      }

      .validated_input_field {
        width: 25rem;
        height: 4.375rem;
        font-family: 'Poppins',sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid #0ff4bd;
        background: transparent;

        background: rgba(15, 244, 189, 0.07);
      }

      .invalid_input_field {
        width: 25rem;
        height: 4.375rem;
        font-family: 'Poppins',sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid red;
        background: rgba(239, 94, 147, 0.07);
      }

      .ant-form-item-control-input-content {
        .ant-input {
          background: transparent;

          .ant-input-lg {
            color: var(--black, #20222a);
            font-family: 'Poppins',sans-serif;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 1.59375rem */
          }
        }
      }

      .login_form_button_disabled {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 2.81rem;
        border-radius: 0.9375rem;
        background: #e2e2e7;
        color: rgba(80, 81, 80, 0.5);
        text-align: center;
        font-family: 'Poppins',sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
        /* 235.294% */
      }

      .login_form_button {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.9375rem;
        background: #0ff4bd;
        color: #20222a;
        text-align: center;
        font-family: 'Poppins',sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
      }

      .inputfield_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.25rem;
        justify-content: center;
      }
    }

    .profiledetails {
      margin:auto;
       .heading {
        margin: auto;
        text-align: center;
      }

      .profileform {
        .subform {
          display: flex;
          flex-direction: row;
          width: 100%;
        }

        .submitbtn,.submitbtn:hover {
          width: 40%;
          background-color: #0FF4BD !important;
          color: black !important;
          font-weight: bold;
          height: 50px;
          margin: auto;
          border: transparent;
        }

        .submitbtnd,.submitbtnd:hover {
          width: 40%;
          background-color: #E2E2E7 !important;
          color: #505150 !important;
          font-weight: 700;
          height: 50px;
          margin: auto;
          border: transparent;
        }

        .subform1 {
          width: 100%;

          // width:100%;
          .input-fieldcolor {
            background-color: rgba(15, 244, 189, 0.07);

            border: 1px solid #0FF4BD;
            //   opacity: 0.2;
            color: black;
            // font-weight: 700;
          }

          input {
            height: 45px;
            width: 96%;
          }
        }

        .skipcard {
          color: #000;
          text-align: center;
          font-family: 'Poppins',sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          opacity: 0.4;
          cursor: pointer
        }

      }
    }
  }

  .google-autosuggestion-list {
    margin-top: 0px;
    width: 100%;
    height: 220px;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    margin-bottom: 108px;
    position: absolute;
    z-index: 1;
    background: #fff;
    padding: 0 10px;
    overflow-y: auto;
    cursor: pointer;
  }
}

@media(max-width:1400px)
{
  .client-select-container .center_container .profiledetails .profileform .subform1 input {
    height: 42px;
    width: 96%;
}
}
@media  (max-height: 640px) {
  .client-select-container .center_container .profiledetails .profileform .subform1 input {
    height: 40px !important;
}
.profileform .submitbtnd,  .profileform .submitbtnd:hover {
  height: 40px !important;
}
}


@media(max-width:1000px) {
  .content-data {
    margin-top: 5rem;
  }

  .profileinfosel {
    font-size: 10px;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;