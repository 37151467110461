.settings {
  height: 100%;
  overflow: hidden;
  .ant-input::placeholder {
  color: #d9d9d9; 
  font-weight: 400;
}
 .bankform .input-field{
  width: 100% !important;
 }
 .bankform .input-field1{
  width: 100% !important;
 }
  .settingstab {
    height: calc(100vh - 195px);
    width: 260px;

    form {
      margin-bottom: 1.25rem;
    }
  }

  .ant-tabs-nav {
    width: 255px;
  }

  .ant-tabs-nav-wrap {
    width: 100%;
  }

  .ant-tabs-nav-list {
    padding-top: .5rem;
    height: 100%;
    overflow-y: auto;
    font-size: 16px;

    .ant-tabs-ink-bar {
      background: transparent !important;
    }
  }

  .ant-tabs-tab,
  .ant-tabs-tab:hover {
    border: 1px solid #ECECEE;
    border-radius: 10px;
    margin: 3% auto 0 auto !important;
    width: 230px;
    padding: 8px 12px !important;
    color: #20222A !important;
    font-weight: 500;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #20222A !important;
  }

  .ant-tabs-tab-active,
  .ant-tabs-tab-active:hover {
    background-color: #0ff4bd;
    border: 1px solid #0ff4bd;
    border-radius: 10px;
    margin: 3% auto 0 auto !important;
    // width: calc(100% - 24px);
    padding: 8px 12px !important;
    color: #20222A !important;
    font-weight: 500;
    width: 230px;
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-tabs>.ant-tabs-nav,
  .ant-tabs-nav-list {
    width: 100%;
    gap: 3.5%;
    height: 100%;
    overflow-y: auto;
  }

  .settingcard {
    width: calc(84vw - 250px);
    //height: calc(100vh - 195px);
    // border: 1px solid #6B6B6B33;
    border-radius: 10px;
    // box-shadow: 10px 0px 54px 0px #0000000D;
    //overflow-y: auto;
    form {
      margin-bottom: 0rem !important;
    }
    .pay-now, .pay-now:hover {
      width: 40% !important;
      margin: 0px !important;
    }
    .pay-now-disabled{
     width: 40% !important;
     margin: 0px !important;
    }
    .cardlistinfo{
      display: flex;
       flex-wrap: wrap;
       height: calc( 100vh - 240px);
       overflow-y: scroll;
      .singlecardimage{
        width: 400px;
        height: 200px;
        display: flex;
        margin:  20px;
        flex-direction: column;
        border-radius: 30px;
        border: 1px solid #0FF4BD;
        position: relative;
        .img1{
          position: absolute;
           height: 148px;
          left: 1.5rem;
        }
        .img2{
          position: absolute;
          left: 1rem;
          top: 1rem;
        }
        .singlecardinfo1{
          height: 100px;
          background-color: rgba(25, 183, 146, 0.2);
          border-radius: 30px 30px 0px 0px;
          position: relative;
          .cardNumber{
            position: absolute;
            bottom: 0rem;
            font-size: 18px;
            font-weight: 500;
            color: #20222A;
           display: flex;
           padding: 0rem 4rem;
           justify-content: space-between;
            width: 100%;
          }
          .radioicon{
            float: right;
            right: 0rem;
            top: 1rem;
            position: relative;
            img{
              width: 18px;
              height: 18px;
            }
          }
          .delete{
            float: right;
            right: 3rem;
            top: 1rem;
            position: relative;
            img{
              width: 18px;
              height: 18px;
            }
          }
        }
        .singlecardinfo2{
          height: 100px;
          z-index: 222;
          background-color: #fff;
          border-radius: 30px;
          .cardnamedate{
            padding: 10px;
            .name{
              font-size: 16px;
              font-weight: 600;
            }
            .date{
              font-size: 16px;
              color: rgba(80, 81, 80, 1);
            }
          }
          .img{
            img{
              width: 100px;
              height: 70px;
              //padding: 10px;
            }
          }
        }
      }
    }
    .addnewcardbtn,.addnewcardbtn:hover{
      position: fixed;
      width: 20%;
      background-color: #0FF4BD !important;
      height: 50px;
      color: #20222A !important;
      font-weight: 500;
      font-size: 18px;
      border-color: #0FF4BD !important;
      bottom: 2rem;
      z-index: 11;
    }
  
  }
  .bankdetailscontainer .bankform .ant-form-item {
    width: 95% !important;
}
  .editsavebtn {
    position: fixed;
    bottom: 4vh;
  }

  .settingnames, .settingnames:hover{
    font-size: 15px;
    color: black !important;
  }

  .imageicons {
    display: inline-block;
    width: 12px;
    height: 16px;
    margin-right: 14px;
  }

  .bankinfo {
    width: 39% !important;
    .bankform{
      width: 94% !important;
    }
  
    .subform {
      display: block;
      width: 100%;
    }

    .bankdetailscontainer {
      form {
        margin-top: 0% !important;
      }

      #bank-form {
        margin-top: 0% !important;
      }
    }

    .bankdetailscontainer .nextbtn,
    .bankdetailscontainer .nextbtn:hover {
      width: 95%;
    }

    .nextbtnd {
      width: 95%;
    }
  

  // ::placeholder {
  //   color: gray
  // }
}

  #name-form {
    width: 94%;
    padding: 10px;
    height: calc(100vh - 247px);
    overflow-y: auto;

    .contentheading {
      color: rgba(80, 81, 80, 1);
      font-size: 16px;
      font-weight: 500;
      margin: 1rem 0;
    }

    .sliderinfo {
      font-weight: 500;
      font-size: 16px;
      display: flex;
      justify-content: center;
    }

    .subform {
      display: flex;
      position: relative;
      flex-wrap: wrap;

      .editicon {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0.5rem;
        top: 1rem;
        z-index: 33;
        cursor: pointer;
      }

      .subforminfo {
        border: 1px solid gray;
        padding: 10px;
        border-radius: 8px;

        .questioninfo {
          padding: 10px;

          .question {
            color: #505150;
            font-size: 16px;
            font-weight: 500;
          }

          .answer {
            color: black;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }

      .ant-slider {
        .ant-slider-handle::before {
          background: transparent;
          top: 12px;
          margin-bottom: 20px;
        }
      }

      .services,
      .sufraceapplied {
        display: flex;
        //width: 100%;
        gap: 5%;
        padding: 10px;
        overflow-x: scroll;

        .singleService {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          width: 200px;
          height: 60px;
          background-color: #FFFFFF;
          border: 1px solid #8492901A;
          border-radius: 10px;
          padding: 10px;
          font-size: 14px;

          .img {
            img {
              width: 40px;
              height: 36px;
              border-radius: 5px;
              margin-right: 10px;
            }
          }
        }

        .singleServiceapplied {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 150px;
          height: 100px;
          background-color: #FFFFFF;
          border: 1px solid #8492901A;
          border-radius: 10px;
          padding: 10px;
          font-size: 14px;

          .img {
            img {
              width: 40px;
              height: 36px;
              border-radius: 5px;
            }
          }
        }
      }

      .subformcontent {
        width: 50%;
        margin-right: 1%;
        cursor: pointer;
      }

      .subformcontent1 {
        width: 48%;

      }

      .input-field {
        height: 55px;
      }

      .input-field1 {
        border: 1px solid #0FF4BD;
        background: #0FF4BD12;
        height: 55px;
      }

      .phonenumber {
        display: flex;
        padding: 10px;
        border-radius: 5px;
        color: #20222A;
        border: 1px solid #0FF4BD;
        background: #0FF4BD12;
        position: relative;
        height: 55px;
        align-items: center;
      }

      .phonenumber1 {
        border: 1px solid #A1A1AA !important;
        background-color: #fff !important;
        color: #A1A1AA !important;
        //border-radius: 12px;
      }

      .tickicon {
        position: absolute;
        right: 2rem;
        top: 1rem;
      }

    }


  }

  .editsave_btn {
    width: 20%;
    // margin-bottom: 3%;
    background-color: #0ff4bd !important;
    color: #20222A !important;
    height: 50px;
    border-radius: 10px;
    font-weight: 500;
    position: fixed;
  }

  .editsave_btndisabled {
    width: 20%;
    background-color: #E2E2E7 !important;
    height: 50px;
    position: fixed;
  }

  #changepwdform {
    width: 50%;
    padding: 20px;

    .save_btn {
      width: 100%;
      margin-bottom: 3%;
      background-color: #0ff4bd !important;
      color: #20222A !important;
      height: 50px;
      font-weight: 500;
    }

    .save_btn_disabled {
      width: 100%;
      // margin-bottom: 3%;
      background-color: #E2E2E7 !important;
      opacity: 0.2;
      color: #50515080 !important;
      height: 50px;
    }
  }

  #locationform {
    width: 95%;
    padding: 10px;

    .input-green,
    .input-green:hover {
      border: 1px solid #0FF4BD !important;
      background: #0FF4BD12 !important;
      height: 45px;
    }

    .subform {
      width: 100%;
      display: flex;

      .subform1 {
        width: 50%;
        margin-right: 1%;

        input {
          color: #20222A;
          font-weight: 500;
        }
      }

      .subform2 {
        width: 48%;

        input {
          color: #20222A;
          font-weight: 500;
        }
      }
    }

    .btnenabled {
      //width: 50% !important;
      color: #20222A !important;
      font-weight: 500;
      cursor: not-allowed !important;
      background: #E2E2E7 !important;
    }

    .btnenabled1 {
    //  width: 50% !important;
      color: #20222A;
      font-weight: 500;
      background: rgb(15, 244, 189);
    }
  }

  .addlocationbtn,
  .addlocationbtn:hover {
    position: fixed;
    width: 20%;
    background-color: #0FF4BD !important;
    height: 50px;
    color: #20222A !important;
    font-weight: 500;
    font-size: 18px;
    border-color: #0FF4BD !important;
    bottom: 2rem;
  }

  .locationlist {
    padding: 10px;
    flex-wrap: wrap;
    //  height: 90%;
    overflow: auto;
   max-height: 70vh;
    .singleitem {
      background: #F9F9F9;
      border: 1px solid #ECECEE;
      padding: 5px 10px;
      width: 350px !important;
      height: 140px;
      border-radius: 10px;

      .singlerow {
        display: flex;

        .name {
          color: #0F172A;
          font-weight: 500;
          font-size: 18px;
        }

        .officeadd {
          color: #0F172A;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}

.serviceinfo {
  position: relative;

  .ant-modal-content {
    width: 500px;
    height: 400px;
  }

  .save,
  .save:hover {
    bottom: 1rem;
    position: absolute;
    width: 200px;
    height: 40px;
    z-index: 33;
    background-color: #0FF4BD;
    border: transparent !important;
    color: black !important;
  }

  .savedisable {
    background-color: gray !important;
    cursor: not-allowed !important;
  }

  // .profileform{
  //   .subform div{

  //     //height: auto !important;
  //   }
  // }
}

.questionmodal {
  z-index: 33;

  .ant-modal-content {
    width: 500px;
    height: 500px;
    overflow-y: scroll;

    .client-select-container {
      height: 62vh !important;
    }

    //  .qs{
    //   //width: 100%;
    //   #dec-form{
    //     width: 80% !important;
    //   }
    //  }
  }
}

.file-upload-box {
  width: 109px;
  background-color: #DADFE4;
  border-radius: 10px;
  height: 109px;
  position: relative;
}

.file-upload-box1 {
  width: 150px;
  background-color: #DADFE4;
  border-radius: 50%;
  height: 150px;
  position: relative;
.ant-spin-container{
  height: 111% !important;
}
  .infoicon {
    position: absolute;
    right: -1rem;
  }
}

.camicon {
  position: absolute;
  top: 65%;
  left: 72%;
  background-color: #0FF4BD;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 4px solid #fff;

  .img {
    top: 6px;
    left: 6px;
    position: relative;

    img {
      width: 20px;
    }
  }
}

.profileplus {
  width: 150px;
  border-radius: 50%;
  height: 150px;
  position: relative;
  object-fit: cover;
}
@media(max-width:1388px)
{
  .settings .settingcard .cardlistinfo .singlecardimage {
    width: 370px;
  }
}
@media(max-width:900px) {
  .settings #name-form .subform {
    display: block;
    width: 100%;

    .subformcontent {
      width: 66%;
    }

    .subformcontent1 {
      width: 66%;
    }
  }
}

.mobilemodal {
  .ant-modal-content {
    width: 600px;
    height: 466px;
    position: relative;

    .crossicon {
      position: absolute;
      right: 2rem;
      top: 2rem;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .welcome-container {
      width: 559px !important;
      height: 400px !important;
      border: none !important;

      .center-container {
        border: none !important;
        width: 100%;
        height: 40vh;
      }

    }
  }

}

@media(max-width:1024px) {
  .settings .locationlist .singleitem {
    width: 320px !important;
  }

  .settings #changepwdform {
    width: 80% !important;
  }

}
@media (max-width:1024px) {
  #name-form{
    width: 100% !important;
  }
  .settings .settingcard .cardlistinfo {
    height: calc(100vh - 350px) !important;
}
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;