.ratingwrapper{
  .ant-progress .ant-progress-bg {
    background-color: #E2AE2C !important;
  }
  
  .ant-progress.ant-progress-status-success .ant-progress-bg {
    background-color: #E2AE2C !important;
  }
  padding: 10px;
  .ratings{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    background-color: #FFF5EA;
    padding: 15px;
    border-radius: 15px;
    .rleft{
      width:50%;
      .rscore{
        span{
          font-weight: 600;
          color: black;
          font-size: 25px;
        }
      }
    }
    .rright{
      width: 50%;
      display: flex;
      justify-content: flex-end;
      //flex-direction: column;
      .rightinfo{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
      .progressbar{
        justify-content: flex-end;
        height: 10px;
        width: 60%;
        font-size: 10px;
        padding-bottom: 20px;
        padding-right: 10px;
        //padding-top: 10px;
        .ratingbar{
          display: flex;
        //  margin: 10px;
         .div1{
          flex: 1;
          margin-top: 4px;
         }
         .progressb{
          flex: 6;
         }
        }
      }
    }
    }
  }
  .peopleratings{
   height: calc(100vh - 280px);
   overflow-y: scroll;

  .peoplerating{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ECECEE;
    border-radius: 20px;
    margin-top: 15px;
    .singlerating{
      display: flex;
      .staricon{
        width: 50px;
        height:50px;
        background-color: #FFF5EA;
        border-radius: 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
        img{
          width: 20px;
          height:20px;
         
        }
      }
    }
    .ratingtime{
      font-size: 12px;
      color: gray;
    }
  }
}
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;