.main_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .center_container {
    // width: 77.5rem;
    // height: 834px;
    width:60%;
    height:85vh;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 5.94rem;
    padding: 15px;
    .ant-input:hover {
      border-color:#0FF4BD !important;
    }

    .req_header {
      // width: 39.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .num_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
      width:100%;
      // width: 446px;
      // height: 429px;
      gap: 10px;
      .sign_in_text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
      .para_container_reset {
        width: 27.875rem;
        color: var(--2, #505150);
        display: flex;
        flex-direction: column;
        text-align: center;

        /* 16_Body_1 */
        font-family: "Poppins",sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem; /* 187.5% */
        p {
          margin-bottom: 0rem;
        }
      }

      .num_field {
        // width: 25rem;
        // height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;

        color: rgba(161, 161, 170, 0.4);
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 1.59375rem */
      }
      .select_field {
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;

        color: rgba(161, 161, 170, 0.4);
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 1.59375rem */
      }
      .validated_input_field1, .validated_input_field1:hover{
        width: 25rem;
        height: 4.375rem;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
      //  border-radius: 0.9375rem;
        border: 1px solid #0ff4bd !important;
        //background: transparent;
        background: rgba(15, 244, 189, 0.07);
      }
      .input_field,.input_field:hover,.input_field:focus{
        border: 1px solid #a1a1aa !important;
        box-shadow: none !important;
      }
      .invalid_input_field {
        width: 25rem;
        height: 4.375rem;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid red;
        background: rgba(239, 94, 147, 0.07);
      }
      .ant-form-item-control-input-content {
        .ant-input {
          background: transparent;
          .ant-input-lg {
            color: var(--black, #20222a);
            font-family: "Poppins",sans-serif;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 1.59375rem */
          }
        }
      }
      .login_form_button_disabled {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 2.81rem;
        border-radius: 0.9375rem;
        background: #e2e2e7;
        color: rgba(80, 81, 80, 0.5);
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem; /* 235.294% */
      }
      .login_form_button {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.9375rem;
        background: #0ff4bd;
        color: #20222a;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
        border: transparent !important;
      }
     
      .inputfield_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.25rem;
        justify-content: center;
      }
    }

    .inputfield_container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.25rem;
    }
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;