.messagecontainer {
    display: flex;
    height: 92%;

    .messagecontent1 {
        flex: 1;

        .sidechat {
            height: calc(100% - 20px);
            overflow-y: scroll;

            .sidechatuseractive {
                border: 1px solid #0FF4BD !important;
                background-color: #0FF4BD12;
            }

            .sidechatuser {
                display: flex;
                border: 2px solid #ECECEE;
                margin-bottom: 10px;
                border-radius: 15px;
                padding: 15px;
                font-size: 12px;
                position: relative;
                cursor: pointer;

                .profileimg {
                    margin-right: 10px;

                    img {
                        width: 55px;
                        height: 55px;
                        border-radius: 50%;
                    }
                }

                .profileinfo {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    //align-items: center;
                    .profilename {
                        color: #000000;
                        font-weight: 500;
                        font-size: 13px;
                    }

                    .profilemsg {
                        color: #A1A1AA;
                        font-weight: 500;
                        font-size: 13px;
                    }
                }

                .readinfo {
                    position: absolute;
                    right: 1rem;
                    top: 1rem;
                    background-color: #DB524E;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    color: #F3F5F7;
                    font-size: 13px;
                    text-align: center;
                }

                .profiletime {
                    display: flex;
                    align-items: flex-end;

                    .time {
                        position: absolute;
                        right: 1rem;
                        bottom: 3px;
                    }
                }
            }
        }
    }

    .messagecontent2 {
        flex: 3;
        margin-left: 10px;
        //height: 100%;
        border: 2px solid #ECECEE;
        border-radius: 15px;
        padding: 5px 15px 15px 15px;

        .messageinfo {
            height: 99%;
            margin-bottom: 10px;

            .messageheading {
               
                padding-bottom: 5px;
                border-bottom: 2px solid #ECECEE;
                align-items: center;
                height: 100px;
                .messagedateadd
                {
                    color:#505150;
                    font-weight:400;
                    font-size: 14px;
                    margin-left:10%;
                }
                .messageflex{
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                .messageimg {
                    img {
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;
                        margin-right: 10px;
                        margin-top: 11px;
                    }
                }

                .messagename {
                    display: flex;
                    flex-direction: column;
                    .messagename1{
                    color: #000000;
                    font-weight: 600;
                    }
                    .messagename2{
                        color: #A1A1AA;
                        font-weight: 400; 
                    }
                }
            }
            }
        }

        .messagechat {
            height: calc(100% - 170px);
            overflow-y: scroll;
            margin-bottom: 10px;

            .messageinfocontent {
                margin-top: 10px;
              

                .messagecontent {
                    display: flex;
                    align-items: center;
                

                    .userimg {
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;

                        }
                    }

                    .userinfo {
                        display: flex;
                        flex-direction: column;

                        .usertimename {
                            display: flex;
                            justify-content: flex-end;

                            .usertime {
                                color: #A1A1AA;
                                font-size: 12px;
                            }

                            .user {
                                font-size: 12px;
                                margin-left: 10px;
                            }
                        }

                        .usercontent {
                            margin-left: 10px;
                            background-color: #F3F5F7;
                            border-radius: 20px 20px 20px 0px;
                            padding: 10px;
                            font-size: 14px;

                        }

                        .usercontent1 {
                            background-color: #19B792;
                            border-radius: 20px 20px 20px 0px;
                            padding: 10px;
                            font-size: 14px;
                            color: #ECECEE;
                        }

                        .usercontent3 {
                            background-color: #FFF !important;
                        }

                        .usercontent2 {
                            font-size: 12px;
                            display: flex;
                            align-items: flex-start;
                            padding-left: 10px;

                            .username {
                                color: black;
                                font-weight: 500;
                                margin-right: 10px;
                            }
                        }

                    }
                }
            }
        }

        .inputtag {
            .fileinput {
                display: none !important;
            }

            .ant-input-affix-wrapper {
                border-radius: 30px !important;
            }

            input {
                height: 45px;
                border-radius: 20px;
            }
        }
    }
}
@media(max-width:1024px)
{
    .messagecontainer .messagecontent2 .messagechat {
        height: calc(100% - 165px);
    }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;