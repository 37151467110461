.createjob {
  width: 100%;
  color: #505150;

  ::placeholder {
    font-family: 'Poppins', sans-serif;
  }

  .ant-switch-checked {
    background: #0FF4BD;
  }

  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #0FF4BD;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: #0FF4BD;
    border: transparent;
  }

  .ant-radio-inner:hover {
    border-color: #0FF4BD !important;
  }

  .createjobinfo {
    width: 80%;
    padding: 10px;

    .locationinputdisable {
      pointer-events: none;
      opacity: 0.4;
    }

    .locationinput {
      margin-bottom: 3rem !important;

      .google-autosuggestion-list {
        margin-top: 0px;
        width: 100%;
        height: 154px;
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
        margin-bottom: 108px;
        position: absolute;
        z-index: 1;
        background: #fff;
        padding: 0 10px;
        overflow-y: auto;
        cursor: pointer
      }
    }

    .inputfeildcolor {
      background: rgba(15, 244, 189, 0.07);
      border: 1px solid #0FF4BD;

      .ant-input-affix-wrapper>input.ant-input {
        background: transparent !important;
      }

      .ant-input {
        background: transparent !important;
      }

      input {
        background: transparent !important;
      }
    }

    .jobtypedisable {
      pointer-events: none;
      opacity: 0.4;
    }

    .jobtype {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .job {
        width: 32%;
        height: fit-content;
        background-color: #F4F7FF;
        cursor: pointer;
        border-radius: 30px;
        border: 1px solid #ECECEE;

        .jobimg {
          width: 100%;
          height: 232px;
        }

        .jobtitle {
          padding: 6px;
        }
      }

      .jobactive {
        width: 32%;
        height: fit-content;
        background-color: black;
        border-radius: 30px;
        border: 1px solid #0FF4BD;

        .jobimg {
          width: 100%;
          height: 232px;

          img {
            border-radius: 22px !important;
          }
        }

        .jobtitle {
          color: #0FF4BD;
          padding: 6px;
        }
      }
    }

    .jobpost {
      width: 100%;

      .jobpostbtn,
      .jobpostbtn:hover {
        width: 25%;
        display: flex;
        margin: auto;
        text-align: center;
        justify-content: center;
        color: black;
        font-weight: 600;
        background-color: #0FF4BD;
        height: 50px;
        align-items: center;
        border-color: transparent;
      }

      .jobpostbtnd {
        width: 25%;
        display: flex;
        margin: auto;
        text-align: center;
        justify-content: center;
        color: rgba(80, 81, 80, 0.50);
        font-weight: 600;
        background: #E2E2E7;
        height: 50px;
        align-items: center;
      }
    }

    .services {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 1rem;

      .servicearray {
        display: flex;
        flex-wrap: wrap;

        .servicesinfo {
          position: relative;
          padding: 10px;
          cursor: pointer;

          .tickactive {
            position: absolute;
            right: 10px;
          }

          .tick {
            display: none;
          }

          .serviceimg {
            width: 150px;
            height: 150px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .serviceimgactive {
            width: 150px;
            height: 150px;
            border: 2px solid #0FF4BD;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .loactiondiv {
      display: flex;
      justify-content: space-between;

      .addlocation {
        color: #0FF4BD;
        font-size: 18px;
        cursor: pointer;
        font-weight: 500;
      }
    }

    .surfaceName1 {
      color: #505150;
      font-weight: 500;
    }

    .surfaceName {
      .surfacelabel {
        margin-bottom: 7px;
      }

      .inputfeildcolor {
        background: rgba(15, 244, 189, 0.07);
        border: 1px solid #0FF4BD;
      }

      .input-field {
        height: 40px;
      }

      .input-field:hover {
        border-color: #0FF4BD !important;
      }
    }

    .uploadfile {
      width: 100px;
      height: 100px;
      background-color: #0FF4BD1A;
      border-radius: 10px;
      position: relative;

      .plusimg {
        position: absolute;
        top: 34%;
        left: 33%;
        cursor: pointer;
      }
    }

    .radiogrp {
      .radiogroup {
        display: flex;
        flex-direction: column;
      }
    }

    .locationinfo {
      gap: 1%;
      width: 100%;
      flex-wrap: wrap;

      .locationtick {
        background-color: #0FF4BD12;
        border: 1px solid #0FF4BD !important;
      }

      .locations {
        border: 1px solid #A1A1AA;
        padding: 12px;
        width: 49.5%;
        border-radius: 10px;
        position: relative;
        margin-bottom: 5px;
        cursor: pointer;

        .locimg {
          position: absolute;
          right: 2rem;
        }

        .locationwall {
          margin: 10px;
          color: black;
          font-size: 18px;
        }

        .locationheading {
          color: black;
          font-weight: 500;
          font-size: 20px;
          //margin:0 10px;
        }

        .locationcontent {
          color: #505150;
          font-size: 14px;
        }
      }
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: #0FF4BD !important;
}

.ant-radio-wrapper .ant-radio:hover::after {
  visibility: none !important;
}

.paymentmodal {
  .ant-modal-content {
    width: 600px;
    height: 520px;

    .ant-modal-body {
      height: 100%;

      .paymentinfodiv {
        display: flex;
        height: 520px;

        .paymentinfodiv1 {
          width: 49%;
          height: 100%;
          margin-right: 1%;

          .paymentinfo {
            // width: 200%;

            .button1,
            .button1:hover {
              width: 100% !important;
              height: 40px !important;
              background-color: rgba(226, 226, 231, 1);
              color: black;
              border: transparent !important;
              font-weight: 500;
              margin-bottom: 5px;
            }

            .button2,
            .button2:hover {
              width: 100% !important;
              height: 40px !important;
              background-color: rgba(15, 244, 189, 1);
              color: black;
              border: transparent !important;
              font-weight: 500;
              margin-bottom: 5px;
            }

          }
        }

        .paymentinfodiv2 {
          width: 50%;
          height: 90%;
          overflow-y: scroll;

          .cards {
            .cardheading {
              margin-bottom: 15px;

              .cardh1 {
                color: rgba(80, 81, 80, 1);
                font-size: 18px;
              }

              .cardh2 {
                color: rgba(15, 244, 189, 1);
                font-size: 18px;
                cursor: pointer;
              }
            }
          }

          //align-items: center;
        }
      }
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;