.earninginfo{
  .earnings{
    display: flex;
    .earning{
      flex: 1;
      border: 1px solid #ECECEE;
      padding: 15px;
      border-radius: 15px;
      margin-right: 5px;
      height: 118px;
      align-items: center;
      .earningprice{
       color: #20222A;
       font-weight: bold;
       font-size: 20px;
      }
    }
    .earningdata{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #71717A;
    }
  }
  .transactions{
    padding: 10px;
    border: 1px solid #ECECEE;
    border-radius: 10px;
    margin-top: 10px;
    .transaction{
      color: black;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      display: flex;
      align-items: center;
    }
    .listyears{
    border: 1px solid #ECECEE;
    border-radius: 10px;
    padding: 10px 20px;
   .ant-space-item{
    color: #20222A !important;
   }
    }
  }
  .transactioninfo{
    padding: 10px;
    height: calc(100vh - 365px);
    overflow-y: scroll;
    .singletransaction{
      margin: 10px 0px;
      .tinfo{
        display: flex;
       .tdata{
        font-size: 12px;
        .thead{
          color: #20222A;
          font-weight: 600;
          font-size: 16px;
        }
        .tdate{
          color: gray;
        }
       }
      }
      .tprice{
        color: #20222A;
        font-weight: 600;
        font-size: 16px;
        font-size: 18px;
       }
    }
  }
}
@media(max-width:1400px)
{
  .earninginfo{
    .earnings{
      .earning{
        height: 88px;
        .earningsimg{
          img{
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;