.form-control:focus {
  border: 1px solid #D1D5DB;
border-color: #D1D5DB !important;
}
.otp-input-container {
justify-content: center;

input.otp-input {
  background: #fff;
  border-radius: 0.75rem !important;
  font-size: 1rem !important;
  border: 1px solid #D1D5DB;
  height: 70px !important;
  width: 70px !important;
  margin-left: 5% !important;

  &:focus-visible {
    outline: none;
  }
}
}
.otp-input-container1 {
justify-content: center;

input.otp-input {
  background: rgba(15, 244, 189, 0.07);
  border-radius: 0.75rem !important;
  font-size: 1rem !important;
  border: 1px solid #D1D5DB;
  height: 70px !important;
  width: 70px !important;
  margin-left: 5% !important;
  border-color: #0ff4bd;

  &:focus-visible {
    outline: none;
  }
}
}
.resendbtn {
span {
  margin-top: 0.6%;
  margin-right: 20px;
  order: 2;
}
}

#otp-resend-root {
span {
  margin-top: 2px;
  margin-left: 20px;
}
}

.otp-input-container>input.otp-input:last-child {
margin-right: 0px !important;
}

.react-tel-input {
width: 60%;
border-radius: 5px;

}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
background-color: transparent;
}
.react-tel-input:hover {
border: 1px solid #0ff4bd !important;
box-shadow: transparent !important;
}

.resend-otp-btn {
color: #0ff4bd !important;

&:disabled {
  color: #ccc !important;
}
&:hover {
  background-color: #fff !important;
}
}
@media(max-width:500px) {
.otp-input-container {
  input.otp-input {
    width: 45px !important;
  }
}
}
@media(max-width:400px) {
.otp-input-container {
  input.otp-input {
    width: 38px !important;
  }
}
}
.welcome-container {
border: 1px solid #E7E8EA;
border-radius: 15px;
font-family: 'Open Sans', sans-serif;
width: 100vw;
height: 100vh;
display: flex;

.center-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  margin: auto;
  width: 60%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .welcomephone-header {
    font-size: 2rem;
    color: black;
    font-weight: 600;
  }
  .btnstyle {
    width: 50% !important;
    height: 60px !important;
    top: 30px !important;
    border-radius: 10px !important;
    background: #0ff4bd !important;
    color: #20222a !important;
    font-weight: 500;
  }
  .btnstyle1,.btnstyle1:hover {
    background: #e2e2e7 !important;
    color: rgba(80, 81, 80, 0.5) !important;
    width: 50% !important;
    height: 60px !important;
    top: 30px !important;
    cursor: not-allowed;
    border-radius: 10px !important;
  }
  .phoneinputdiv1{
    margin-left: 33%;
    margin-top: 6%;
    width: 80% !important;
  }
  .phoneinputdiv {
    margin-left: 33%;
    margin-top: 6%;
    width: 80% !important;
    .react-tel-input
    {
       border: 1px solid #0ff4bd  !important;
    }
    .phoneinput1{
      background-color: rgba(15, 244, 189, 0.07);
      color: black;
      font-weight: 500;
    }
  }
  .btnstyleotp {
    background: #0ff4bd !important;
    color: black !important;
    width: 50% !important;
    height: 50px !important;
    border-radius: 5px;
    //margin-top: 10px;
    font-weight: 700 !important;
    justify-content: center;
    display: "flex";
   // width: "100%";
    margin: "10px auto 10px 23%";
    align-items: "center";
  }
  .btnstyle1otp {
    background: #e2e2e7;
    color: rgba(80, 81, 80, 0.5);
    width: 50% !important;
    height: 50px !important;
    border-radius: 5px;
    //margin-top: 10px;
    font-weight: 700 !important;
    justify-content: center;
    display: "flex";
   // width: "100%";
    margin: "10px auto 10px 23%";
    align-items: "center";
  }
}
}
.clientcont {
.clientcont1 {
  .welcomephone-header {
    font-size: 1.8rem;
    color: black;
    font-weight: 600;
    text-align: center;
  }
  .btnstyle {
    width: 55% !important;
    height: 50px !important;
    top: 30px !important;
    border-radius: 10px !important;
    background: #0ff4bd !important;
    color: #20222a !important;
    font-weight: 500;
    margin: auto;
  }
  .btnstyle1 {
    background: #e2e2e7 !important;
    color: rgba(80, 81, 80, 0.5) !important;
    width: 55% !important;
    height: 50px !important;
    top: 30px !important;
    border-radius: 10px !important;
    margin: auto;
  }
  .phoneinputdiv {
    margin-left: 24%;
    margin-top: 6%;
    width: 90% !important;
    .react-tel-input
    {
       border: 1px solid #0ff4bd  !important;
    }
    .phoneinput1{
      background-color: rgba(15, 244, 189, 0.07);
      color: black;
      font-weight: 500;
    }
  }
  .phoneinputdiv1 {
    margin-left: 24%;
    margin-top: 6%;
    width: 90% !important;
    .phoneinput:hover
    {
      border-color:  none !important;
    }
  }
  .btnstyleotp {
    background: #0ff4bd !important;
    color: black !important;
    width: 60% !important;
    height: 50px !important;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 700;
  }
  .btnstyle1otp {
    background: #e2e2e7;
    color: rgba(80, 81, 80, 0.5);
    width: 60% !important;
    height: 50px !important;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 700;
  }
}
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;