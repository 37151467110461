 .custom-dropdown {
   .ant-dropdown-menu {
     border-radius: 15px;
     border: 1px solid rgba(0, 0, 0, 0.1);
     background: white;
     box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
     padding: 15px;
     overflow-y: auto;
     height: 420px;
     width: 35vw;

     .ant-dropdown-menu-item {
       padding: 6px;
       background: transparent;
       border: 1px solid rgba(236, 236, 238, 1);
       border-radius: 15px;
       margin-bottom: 10px;

       .notificationinfo {
         .notimg {
           img {
             width: 50px;
             height: 50px;
             border-radius: 15px;
           }
         }

         .timeinfo {
           font-size: 15px;
           color: gray;

           img {
             width: 20px;
             height: 20px;
           }
         }
       }

     }

     .unread,
     .unread:hover {
       border: 1px solid rgba(15, 244, 189, 1);
       background-color: rgba(15, 244, 189, 0.07);
     }

   }
 }



 .notificationicon {
   width: 35px;
   height: 39px;
   background-color: rgba(15, 244, 189, 1);
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
 }

 .bedge-icon {
   background: #e04a4a;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   color: #fff;
   font-size: 10px;
   font-weight: 700;
   position: absolute;
   top: 0;
   right: 0;
   width: 15px;
   height: 15px;
 }
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;