.locationcard {
  width: 70%;
  margin: auto;
  height: 20px;
  .ant-card {
    .ant-card-body {
      width: 100%;
      height: 30px !important;
    }
  }
}
.addressdetails {
  font-size: 16px;
}
.sidebar {
  background: black;
  box-shadow: 0px 10px 60px rgba(15, 23, 42, 0.1);
  border-radius: 12px 12px 12px 12px;
  width: 14vw;
  height: 100%;
  position: relative;
  .sidebar-toggler {
    background: #fff;
    width: 22px;
    border-radius: 50%;
    padding-left: 3px;
    position: absolute;
    top: 60px;
    right: -10px;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
    z-index: 99;
  }
  .sidebarmenu {
    background: none;
    margin-top: 6%;
    display: flex;
    flex-direction: column;

    .logout-btn {
      position: absolute;
      left: 0;
      bottom: 2%;
      right: 0;
      cursor:pointer
    }

    .customclass {
      text-overflow: unset;
      display: flex;
      align-items: normal;
      height: fit-content;
      padding: 12px;
      margin: 3% auto;

      .ant-menu-title-content {
        display: flex;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
          overflow: visible;
        }
      }
    }

    .sidemenuitem {
      margin-left: 10px;
      font-weight: 400;
      font-size: 17px;
      white-space: initial;
      line-height: 1.4;
    }

    .sideweight {
      font-weight: 600;
    }

    .sideweightwhite {
      font-weight: 400;
    }
  }

  .ant-menu-item {
    color: #ffffff99;
  }
}
.helpcard {
  .helpcardheading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3%;
    color: #111111;
    font-weight: 600;
    font-size: 18px;
  }
  .helpcontent {
    color: #6b6b6b;
    margin-bottom: 5%;
    font-size: 14px;
  }
}

.ratingview .ant-modal-content {
  width: 623px;
  height: 568px;

  .ratingwrapper .peopleratings {
    height: calc(100vh - 400px) !important;
    overflow-y: scroll !important;
  }

  .ratingwrapper .ratings .rright .rightinfo {
    align-items: flex-start;

    .progressbar {
      width: 100%;
    }
  }
}

.aipimg {
  padding: 10px;
  width: 100%;
  img {
    margin-top: 20px;
  }
}
@media (max-width: 1500px) {
  .sidebar .sidebarmenu .customclass {
    margin: 2% auto !important;
  }
  .sidebar {
    .sidemenuitem {
      font-size: 13px;
    }
  }
}
@media (max-width: 1300px) {
  .sidebar {
    width: 180px;

    .sidemenuitem {
      font-size: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .sidebar {
    width: 55px !important;
  }
   .sidebar .sidebarmenu .customclass {
    display: block;
  }
  .sidebar .sidebarmenu .sidemenuitem {
    display:none
  }
  .aipimg {
    padding: 0px;
    img {
      margin-top: 30px;
      width: 45px !important;
    }
  }
}
@media (max-height: 580px) {
  .sidebar .sidebarmenu .customclass {
    margin: 0% auto !important;
  }
}

@media (max-width: 500px) {
  .addressdetails {
    font-size: 10px !important;
  }

  .locationimg {
    img {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-height: 700px) {
  .sidebarmenu {
    margin-top: 1% !important;
  }
}
@media (max-height: 1200px) and( max-width:900px) {
  .aipimg {
    width: 136px !important;
  }
}
@media (max-width: 1200px) {
  .aipimg {
    width: 140px;
  }
}
.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark > .ant-menu .ant-menu-item-selected {
  color: #003b5b !important;
  font-weight: 600 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
  background-color: #0ff4bd !important;
}

.logoutmodal {
  .crossImage {
    margin-bottom: 1.5rem;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
  .reasoninput:hover {
    border: 1px solid #0ff4bd;
    width: 100%;
  }
  .reasoninput {
    border: 1px solid #a1a1aa;
    margin: 0px;
  }
  .logoutdesc {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #111111;
    font-weight: 600;
    font-size: 22px;
    width: 280px;
    margin: auto;
    text-align-last: center;
  }
  .logoutdesc1 {
    font-size: 18px !important;
  }
  .info {
    line-height: 25px;
  }

  .confirmButtons {
    justify-content: space-between;
    display: flex;
    height: 50px;
    margin-bottom: 20px 0 0 10px;

    .yesbtn {
      width: 39%;
      height: 100%;
      background-color: #e7e8ea;
      padding: 8px 12px;
      border-radius: 15px;
      outline: none;
      border-color: #e7e8ea;
      font-size: 16px;
      color: black !important;
      font-weight: bold;
      border: transparent !important;
    }
    .yesbtn1 {
      cursor: not-allowed;
      background-color: #50515080 !important;
      color: gray !important;
    }

    .nobtn,
    .nobtn:hover {
      width: 56%;
      height: 100%;
      margin-left: 5px;
      background-color: #0ff4bd;
      color: black;
      font-weight: bold;
      padding: 8px 15px;
      border-radius: 15px;
      font-size: 16px;
      border: transparent !important;
    }
  }

  .ant-modal-content {
    top: 30%;
    padding: 24px 46px;
  }
}
@media (max-width: 950px) {
  .slick-slider {
    width: 86% !important;
  }
  .slick-next {
    right: -20px;
  }
  .jobsdashboard .jobs-tab .singlejobs {
    width: 70vw;
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;