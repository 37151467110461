.newjobslist {
  overflow-x: hidden;
  height: 95%;

  .singlejob {
    background-color: #f4f7ff;
    width: 82vw;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    .row1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      column-gap: 2%;
      cursor: pointer;
      color: #20222a;
      font-weight: 600;

      .para1 {
        .rowfirst {
          color: #20222a;
          font-weight: 500;
          font-size: 18px;
        }

        .rowsecond {
          font-size: 15px;
          color: #505150;
          font-weight: 500;
        }

        .services {
          color: #a1a1aa;
          font-size: 12px;
        }
      }

      .para2 {
        border: 1px solid #0ff4bd;
        padding: 10px;
        border-radius: 10px;
        background-color: #19b7921a;
        text-align: center;

        .ammount {
          color: #19b792;
          font-weight: 600;
          font-size: 18px;
        }

        .approxcost {
          color: #505150;
          font-size: 10px;
          font-weight: 600;
          text-align: center;
        }
      }

      .para2lift {
        display: flex;
        align-items: end !important;
        font-size: 15px;
      }

      .locationbtns {
        .notbtn {
          background-color: #f1f1f1;
          margin: 10px;
          color: black;
          font-weight: 500;
        }

        .applybtn {
          background-color: #0ff4bd;
          color: black;
          font-weight: 500;
        }
      }
    }
  }

  .calenderimage {
    border-left: 2px solid grey;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 5px;
  }

  .clockimage {
    border-left: 2px solid grey;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 5px;
  }
}

.singlejobinfo {
  overflow-y: scroll;
  height: calc(100vh - 215px);
  .typeofjob {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 35px;
    border: 1px solid rgba(236, 236, 238, 1);
    width: '100%';
    padding: 1rem;
    height: 65px;
    background-color: rgba(244, 247, 255, 1);
    .typeofjobname {
      font-size: 18px;
      font-weight: 600;
    }
    .typeofjobimg {
      display: flex;
      align-items: center;
      background-color: black;
      color: white;
      padding: 10px 17px;
      border-radius: 34px;
      border: 1px solid rgba(15, 244, 189, 1);
      max-width: 172px;
      height: 52px;
      .jobname {
        font-size: 16px;
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }
    }
  }
  .liftbox {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .liftinfo {
    margin-bottom: 15px;
  }
  .surfacedetails {
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 50%;
    margin: 1rem 0px;
    hr {
      background: rgba(0, 0, 0, 0.1);
      height: 0.9px;
      margin: 1px;
    }
    .surfaceiteminfo {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .surfaceitemname {
        color: rgba(159, 168, 177, 1);
        font-size: 18px;
      }
      .surfaceitemvalue {
        color: black;
        font-weight: 600;
        font-size: 18px;
      }
      .surfaceitemnamespan {
        color: rgba(159, 168, 177, 1);
        font-weight: 500;
      }
    }
  }
  .statusinfo {
    color: black;
    font-size: 16px;
    height: 24px;
    width: 112px;
    border-radius: 12px;
    text-align: center;
  }
  .headinginfo {
    color: #505150;
    font-size: 16px;
  }
  .statuspast {
    background-color: #44ef8c;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  }
  .statuspayment {
    background-color: #ff8d8d;
    width: 160px;
  }
  .statuson {
    background-color: #72dfe5;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  }
  .statusup {
    background-color: #0ff4bd;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  }
  .jobinfodetails {
    background-color: #eefefa;
    padding: 10px;
    width: 100%;
    border-radius: 10px;

    .editpost,
    .editpost:hover {
      width: 130px;
      background: #0ff4bd;
      color: black;
      padding: 3px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      font-weight: 600;
      margin-top: 12px;
      cursor: pointer;
      border: transparent;

      .editcontent {
        font-size: 16px;
      }
    }
    .editpostd,
    .editpostd:hover {
      background-color: #e2e2e7;
      cursor: not-allowed;
    }

    .row1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .para1 {
        .rowfirst {
          color: #20222a;
          font-weight: 500;
          font-size: 18px;
        }

        .rowsecond {
          font-size: 15px;
          color: #505150;
        }
      }

      .para2 {
        padding: 10px;
        border-radius: 10px;
        color: #0ff4bd;
        font-size: 20px;
        font-weight: 500;
        text-align: center;

        .ammount {
          color: #19b792;
          font-weight: 600;
          font-size: 22px;
        }

        .approxcost {
          color: #505150;
          font-weight: 500;
          font-size: 8px;
          text-align: center;
        }
      }
    }
  }

  .services,
  .sufraceapplied {
    display: flex;
    gap: 5%;
    padding: 10px;
    overflow-x: scroll;

    .singleService {
      display: flex;
      align-items: center;
      width: 200px;
      height: 60px;
      background-color: #ffffff;
      border: 1px solid #8492901a;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;

      .img {
        img {
          width: 40px;
          height: 36px;
          border-radius: 5px;
          margin-right: 10px;
        }
      }
    }

    .singleServiceapplied {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      height: 100px;
      background-color: #ffffff;
      border: 1px solid #8492901a;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;

      .img {
        img {
          width: 40px;
          height: 36px;
          border-radius: 5px;
        }
      }
    }
  }
  .serviceheading {
    color: black;
    font-weight: 600;
    font-size: 17px;
  }

  .liftservice {
    width: 175px;
    background: #f8312f0d;
    height: 50px;
    padding: 13px;
    text-align: center;
    border-radius: 22px;
    color: red;
    font-weight: 500;

    img {
      width: 30px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .doucmentimages {
    .images {
      display: flex;

      .image {
        width: 150px;
        height: 150px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }

  .additionalnote {
    color: #a1a1aa;
  }
}

.locationinfo {
  font-size: 14px;
  flex: 2;
}

.locationbtns {
  .notbtn,
  .nobtn:hover {
    background-color: #f1f1f1 !important;
    margin-right: 10px;
    color: black !important;
    font-weight: 600;
    width: 170px;
    height: 43px;
    border-radius: 13px;
    font-size: 17px;
    border: transparent;
  }

  .applybtn,
  .applybtn:hover {
    background-color: #0ff4bd;
    color: black !important;
    font-weight: 600;
    width: 140px;
    height: 43px;
    border-radius: 13px;
    font-size: 17px;
    border: transparent;
  }
  .applybtnd {
    cursor: not-allowed !important;
    background-color: #e2e2e7 !important;
  }

  .appiedbtn,
  .appiedbtn:hover {
    width: 195px;
    background-color: #e2e2e7;
    color: #50515080 !important;
    height: 43px;
    cursor: not-allowed;
    font-weight: 500;
    border: transparent;
    font-size: 17px;
    border-radius: 13px;
  }

  .messagebtn,
  .messagebtn:hover {
    width: 165px;
    border: 1px solid #0ff4bd !important;
    color: #20222a !important;
    height: 43px;
    cursor: pointer;
    font-size: 17px;
    margin: 10px;
    font-weight: 500;
    border-radius: 13px;
  }
}

.sucesspopup {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;

  .crossicon {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 2rem;
    top: 0;
  }

  .sucessheading {
    color: black;
    font-size: 18px;
    font-weight: 600;
  }

  .content {
    color: #505150;
    font-weight: 400;
    font-size: 15px;
  }

  .termscontentbtn,
  .termscontentbtn:hover {
    height: 55px;
    color: black !important;
    border: 1px solid #0ff4bd !important;
    background-color: #0ff4bd;
    font-weight: 500;
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
  }
}

.popuppdf {
  .ant-modal-content {
    width: 500px;
    height: 500px;
    top: -1px;
  }

  .ant-modal-body {
    width: 100%;
    height: 91%;
    margin-top: 20px;
  }

  img {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 8px;
    right: 5px;
  }
}

.addexpensemodal {
  .ant-modal-content {
    height: 500px !important;
  }
}

.expensemodalinfo {
  .ant-modal-content {
    height: 550px !important;
  }
}

.expensemodal {
  text-align: center;

  .ant-modal-content {
    width: 450px;
    height: 640px;
    top: -1px;
  }
  .inputvalid {
    background-color: #eefefa !important;
    border: 1px solid #0ff4bd !important;
    input {
      background-color: #eefefa !important;
      // border: 1px solid #0ff4bd !important;
    }
  }
  .inputs {
    border-radius: 12px;
    height: 60px;
    input {
      padding: 10px;
    }
  }
  .ant-input-prefix {
    font-size: 20px;
  }
  .expenseimg {
    width: 400px;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }
  .expensebtns {
    .rejectbtn {
      width: 195px;
      background-color: #e2e2e7;
      color: black !important;
      font-weight: 500;
      border: transparent;
      font-size: 17px;
      border-radius: 13px;
      height: 55px;
    }
    .acceptbtn {
      height: 55px;
      width: 195px;
      color: black !important;
      border: 1px solid #0ff4bd !important;
      background-color: #0ff4bd;
      font-weight: 500;
      border-radius: 10px;
      font-size: 17px;
      cursor: pointer;
    }
  }
  .crossimg {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
  }

  .expenseslist {
    height: 100px;
    overflow-y: scroll;

    .expensedata {
      border: 1px solid #8492901a;
      padding: 10px;
      margin-top: 10px;
      border-radius: 10px;
      height: 55px;
    }

    .expensel {
      .expensename {
        color: gray;
        font-weight: 500;
        font-size: 16px;
      }

      .expenseimg {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }

    .expenseprice {
      color: black;
      font-size: 16px;
    }
  }
  .expenseslist1 {
    height: 160px !important;
  }

  .expenseheading {
    font-size: 22px;
    color: #20222a;
    font-weight: bold;
    margin: 10px;
    text-align: center;
  }

  .expenseinput {
    height: 120px;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .expenseinputfilled {
    background-color: #eefefa;
    border: 1px solid #44ef8c;
  }

  .addexpensebtn,
  .addexpensebtn:hover {
    width: 140px;
    color: black !important;
    font-weight: 600;
    font-size: 15px;
    height: 40px;
    background-color: #f6f6fb;
    border-radius: 10px;
    border: transparent !important;
  }

  .completebtnenable,
  .completebtnenable:hover {
    background-color: #0ff4bd !important;
    color: black !important;
  }

  .completebtn,
  .completebtn:hover {
    width: 100%;
    height: 50px;
    border: transparent !important;
    background-color: #e2e2e7;
    font-size: 15px;
    color: #50515080;
    font-weight: 600;
  }

  .expensebtn {
    .cancelbtn,
    .cancelbtn:hover {
      color: black !important;
      font-weight: 600;
      background-color: #f6f6fb;
      width: 150px;
      height: 44px;
      border-radius: 12px;
      margin: 1rem;
      border: transparent !important;
    }

    .addexpensebtnd {
      color: #50515080 !important;
      background-color: #e2e2e7 !important;
    }

    .addexpensebtn {
      color: black;
      font-weight: 600;
      background-color: #0ff4bd;
      width: 180px;
      height: 44px;
      border-radius: 12px;
      margin: 1rem;
    }
  }

  .expensepic {
    border: 1px solid #ececee;
    border-radius: 18px;
    display: flex;
    height: 168px;
    object-fit: contain;
    position: relative;

    .jobimg {
      width: 100%;
      height: 100%;

      .jobimage {
        width: 100%;
        height: 100%;
        border-radius: 18px;
      }
    }

    .expensedetails {
      display: flex;
      flex-direction: column;
      margin: auto;

      .uploadimg {
        width: 40px;
        height: 60px;
        margin: auto;
      }

      .uploadpic,
      .uploadpic:hover {
        width: 150px;
        //height: 40px;
        border-radius: 14px;
        border: transparent;
        color: black;
        font-weight: 500;
        background-color: #0ff4bd;
        padding: 13px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.statusinfo {
  color: black;
  font-size: 16px;
  height: 24px;
  width: 112px;
  border-radius: 12px;
  text-align: center;
}
.statuspast {
  background-color: #44ef8c;
}
.statuspayment {
  background-color: #ff8d8d;
  width: 160px;
}
.statuson {
  background-color: #72dfe5;
}
.statusup {
  background-color: #0ff4bd;
}
.statuspending {
  background-color: #ffd912;
}
.staturejected {
  background-color: red;
  color: #fff;
}
.ant-input:hover {
  border-color: #0ff4bd !important;
}
.expenseslist {
  .expensedata {
    border: 1px solid #8492901a;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    // height: 65x;
  }

  .expensel {
    .expensename {
      color: gray;
      font-weight: 500;
      font-size: 16px;
    }

    .expenseimg {
      width: 50px;
      height: 47px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }

  .expenseprice {
    color: black;
    font-size: 16px;
  }
  .pendingexp {
    color: #0ff4bd;
    font-weight: 600;
    font-size: 16px;
  }
  .paidexpense {
    color: #0ff4bd;
    font-weight: 600;
    font-size: 16px;
  }
  .rejectedexpense {
    color: red;
    font-weight: 600;
    font-size: 16px;
  }
}

@media (max-width: 1520px) {
  .locationbtns {
    flex: none !important;
  }
}

@media (max-width: 1200px) {
  .newjobslist .singlejob {
    width: 80vw;
  }
  .notbtn {
    width: 130px !important;
    font-size: 12px !important;
  }

  .applybtn {
    width: 130px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 1024px) {
  .newjobslist .singlejob {
    width: 89vw;
  }
}
@media (max-width: 1024px) {
  .singlejobinfo {
    overflow-y: scroll;
    height: calc(100vh - 300px) !important;
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;