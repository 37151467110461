.main_container_phone {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .center_container_phone {
    width: 65%;
    height: 80vh;
    margin: auto;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 10px;

    .req_header {
      width: 39.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .num_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 40px;

      h1 {
        color: #000;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }

      .para_container {
        width: 25.125rem;
        color: var(--2, #505150);
        display: flex;
        flex-direction: column;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;

        p {
          margin-bottom: 0rem;
        }
      }

      .num_field {
        width: 25rem;
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;
        color: rgba(161, 161, 170, 0.4);
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .select_field {
        // width: 25rem;
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;
        color: rgba(161, 161, 170, 0.4);
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 1.59375rem */

      }

      .input-container {
        position: relative;

        .ant-input {
          margin-left: 2.44rem;
          font-family: "Poppins",sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          /* 1.59375rem */
          padding-top: 1%;
        }

        .ant-select-selector {
          .ant-select-selection-item {
            color: var(--black, #20222a);
            font-family: "Poppins",sans-serif;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }

      .prefix {
        position: absolute;
        top: 33%;
        left: 30px;
        z-index: 10;
        width: 5.5rem;
        height: 4.38rem;

        .country_code {
          color: var(--black, #20222a);
          font-family: "Poppins",sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }

      .input-element {
        padding-left: 80px;
      }

      .validated_input_field {
        width: 25rem;
        height: 4.375rem;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid #0ff4bd;
        background: transparent;
        background: rgba(15, 244, 189, 0.07);
      }

      .invalid_input_field {
        width: 25rem;
        height: 4.375rem;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid red;
        background: rgba(239, 94, 147, 0.07);
      }

      .ant-form-item-control-input-content {
        .ant-input {
          background: transparent;

          .ant-input-lg {
            color: var(--black, #20222a);
            font-family: "Poppins",sans-serif;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 1.59375rem */
          }
        }
      }

      .login_form_button_disabled {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 2.81rem;
        border-radius: 0.9375rem;
        background: #e2e2e7;
        color: rgba(80, 81, 80, 0.5);
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
        /* 235.294% */
      }

      .login_form_button {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.9375rem;
        background: #0ff4bd;
        color: #20222a;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
      }

      .inputfield_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.25rem;
        justify-content: center;
      }
    }

    .otp_container {
      width: 25rem;
      height: 4.375rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 2.5rem;
      margin-top: 2.5rem;

      .otp_input {
        width: 4.375rem;
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.6875rem;
        border: 1px solid #a1a1aa;
        background: #fff;
        color: #20222a;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.5rem;
        /* 222.222% */
      }

      .validated_otp {
        width: 4.375rem;
        height: 4.375rem;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid #0ff4bd;
        text-align: center;
        flex-shrink: 0;
        background: rgba(15, 244, 189, 0.07);
      }

      .inputfield_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.25rem;
      }
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;