.dashboard-wrapper{
  width:100vw;
  height:100vh;
  display: flex;
  overflow-y: hidden !important;
  
 .dashboard-left{
  //width:15vw;
  height:100%;
  padding: 5px 0px 5px 5px;
}
.dashboard-right{
  padding:10px 1.5rem;
  width:86%;
  box-shadow: 10px 0px 54px 0px #0000000D;
  .dashboard-content{
    width:100%;
    height: calc(100vh - 80px);
  }
  .ant-spin-nested-loading {
    height:100%;
    overflow-y: hidden !important;
  }
  .ant-spin-container{
    height:100%;
    overflow-y: hidden !important;
  }
  .footermenu{
    position: fixed;
    height: 12px;
    bottom: 1rem;
    width: 86%;
    display: flex;
    justify-content: center;
  }
}

}
@media(max-width:1024px)
{
  .dashboard-wrapper {
  height: 99vh !important;
  .dashboard-right {
    width: 95% !important;
}
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;