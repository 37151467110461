.footer {
  width: 100%;
  height: 120px;
  padding: 30px 10px 10px 10px;
  background-color: black;
  .footerline {
    width: 100%;
    display: flex;
    .footerimg {
      flex: 2;
    }
    .footercontent {
      flex: 1;
      color: #fff;
      display: flex;
      justify-content: space-between;
    .content span{
      margin-right: 10px;
    }
    }
    .copycontent {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      color: #fff;
    }
  }
}

@media(max-width:1000px) {
  .footer {
    .footerline {
      .footerimg {
        flex: 1;
      }
      .footercontent {
        flex: 2;
      }
    }
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;