.dashboard{
  .earninginfo{
    margin-bottom:26px;
  }
  .graphcal{
    display: flex;
   .calenderinfo{
    border: 1px solid #ECECEE;
    padding: 10px;
    height: calc(100vh - 300px);
    margin-top: 0.5rem;
    width: 26vw;
    border-radius: 15px;
   }
  }
}
.report-wrapper {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.report-page {
  width: 100%;
  height: fit-content;
  padding: 0 5px 1rem;
  margin-top: 0.5rem;

  .header {
    gap: 1rem;

    .item {
      background: #FFFFFF;
      border: 1px solid rgba(107, 107, 107, 0.2);
      border-radius: 10px;
      padding: 0.5rem 1rem;
      min-width: fit-content;
      max-width: 320px;
      flex: 1;
      .item-name {
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        color: #6B6B6B;
      }   
      .textsuccess,
      .textdanger,.textZero {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }

  .content-left {
    background: #FFFFFF;
    border: 0.5px solid rgba(107, 107, 107, 0.2);
    box-shadow: 0px 10px 90px rgba(0, 59, 91, 0.05);
    border-radius: 15px;
    padding: 1rem 1.25rem;
    min-width: 560px;
    width: calc(85vw - 450px);

    .left-header {
      margin-bottom: 1rem;
      .earning {
        font-weight: 700;
        font-size: 20px;
        color: #003B5B;
        line-height: 30px;
      }
      .button-wrapper {
        column-gap: 1rem;
        button {
          height: 41px;
          background: #FFFFFF;
          border: 1px solid #003B5B;
          border-radius: 10px;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: #003B5B;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 1rem;
        }
      }
      .ant-select-selector {
        height: 41px;
        background: #FFFFFF;
        border: 1px solid #003B5B !important;
        padding: 12px 1rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none !important;
      }

      .ant-select-arrow,
      .ant-select-selection-item {
        font-weight: 500;
        font-size: 14px;
        color: #003B5B;
      }
    }

    .ant-menu {
      position: absolute;
      right: -4%;
      top: 110%;
      z-index: 2;
      border-radius: 20px;
      background: #FFF;
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

      .ant-menu-item:last-child {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-menu-title-content {
          display: none;
        }
      }

      .ant-menu-item:last-child:active,
      .ant-menu-item:last-child:hover,
      .ant-menu-item-selected {
        background-color: #FFFFFF;
        border: none;
      }

      .ant-picker {
        width: 100%;
        height: 100%;
        padding: 6px 0;
        box-shadow: none;
        border: none;
        // flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;
      }

      .ant-picker-range-separator,
      .ant-picker-suffix,
      .ant-picker-active-bar {
        display: none;
      }
    }
  }

  .content-right {
    width: 28%;
    max-width: 300px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    
    .heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #003B5B;
    }

    .item {
      background: #FFFFFF;
      border-radius: 10px;
      width: 100%;
      font-weight: 500;
      font-size: 12px;
      color: #6B6B6B;

      span {
        display: inline-block;
        height: 7px;
        width: 7px;
        background: #6B6B6B;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }

  .recharts-cartesian-grid-horizontal line {
    stroke-dasharray: 0;
    height: 5px;
  }

  .recharts-cartesian-grid-vertical line {
    display: none;
  }

  .custom-tooltip {
    background-color: #FFFFFF;
    padding: 6px 20px;
    font-weight: 600;
    font-size: 12px;
    color: #111111;
    border-radius: 6px;
    height: 50px;
    -moz-box-shadow: 0 0 8px #c7c7c7;
    -webkit-box-shadow: 0 0 8px #c7c7c7;
    box-shadow: 0 0 8px #c7c7c7;
  }

  .custom-tooltip::after {
    content: "";
    position: absolute;
    top: 98%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: #FFFFFF transparent transparent transparent;
  }
  .recharts-cartesian-grid-horizontal line:first-child {
    display: none;
  }
  .session-duration-tooltip {
    max-width: 250px;
    position: relative;
    padding: 0.5em;
    border-radius: 5px;
    background-color: var(--bg-color);
    color: aliceblue;
  }

  .session-duration-tooltip::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: var(--bg-color) transparent transparent transparent;
  }
}

.header-report {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
  position: relative;

 

  .select-date-range {
    position: absolute;
    width: 335px;
    height: fit-content;
    right: 0;
    top: 70px;
    background: #FFFFFF;
    box-shadow: 0px 4px 34px rgba(0, 59, 91, 0.1);
    border-radius: 20px;
    z-index: 2;
    padding: 1rem;
    .dates{
      .datep{
        input{
        height:40px;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: -13%;
      right: 30px;
      margin-left: -8px;
      border-width: 19px;
      border-style: solid;
      border-color: #FFFFFF;
      border-left: 10px solid transparent;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    .heading {
      color: #003B5B;
      font-size: 18px;
      font-weight: 600;
    }
    .exportdisable{
      opacity:0.2 !important;
    }

    a ,.exportbtn{
      width: 100%;
      height: 40px;
      display: flex;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      border-radius: 10px;
      background: #003B5B;
      border: 1px solid #003B5B;
      box-shadow: 0px 8px 20px 0px rgba(0, 59, 91, 0.16);
    }
  }

  a {
    width: 150px;
    height: 50px;
    display: flex;
    padding: 8px 10px;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    background: #003B5B;
    border: 1px solid #003B5B;
    box-shadow: 0px 8px 20px 0px rgba(0, 59, 91, 0.16);
  }
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-select-dropdown .ant-select-item-option-content {
  font-weight: 500;
  font-size: 14px;
  color: #003B5B;
  text-align: center;
}
@media(max-width:1400px)
{
  .dashboard .graphcal .calenderinfo {
    border: 1px solid #ECECEE;
    // padding: 10px;
    height: calc(100vh - 211px); 
    margin-top: 0.5rem;
    width: 29vw;
    border-radius: 15px;
}
.dashboard .earninginfo{
  margin-bottom:0px;
}
}

@media(max-width: 1250px) {
  .report-page {
    .content-left {
      width: 100% !important;
     // margin: 15px;
    }
   .report-wrapper {
    //height: calc(100vh - 80px);
    overflow-y: hidden !important;
}
    .content-right {
      flex-direction: row;
      justify-content: space-between;
      max-width: 100%;
      width: 100%;
      margin-top: 1.25rem;
    }
   
  }
  .dashboard .graphcal {
    display: block !important;
    height: 79vh;
    overflow-y: scroll;
}
.report-wrapper{
height:38vh !important;
}
.dashboard .graphcal .calenderinfo {
    border: 1px solid #ECECEE;
    padding: 10px;
    height: 38vh;
    /* height: 91vh; */
    /* height: calc(100vh - 222px); */
    margin-top: 0.5rem;
    width: 74vw;
    border-radius: 15px;
}
}
@media(min-width:1900px)
{
   .dashboard{
    .calenderinfo{
      height: 41vh !important;
    }
   }
}
@media(max-width:900px)
{
   .graphcal{
    display: block;
   }
}
@media(max-width:800px)
{
  .dashboard .graphcal .calenderinfo {
    height: 43vh !important;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;