.containercontact {
  //padding: 2rem;
  //width: 100%;
  margin: 0px 3rem;

  h1 {
    font-size: 70px;
  }

  p {
    font-size: 13px;
    width: 50%;
    margin: 10px auto;

  }

  .contactcontent {
    width: 45%;
    color: rgba(80, 81, 80, 1);
  }

  .contactheading {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .contactusmails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 48vh;
    margin: 2rem 0px;

    .content1 {
      background-color: rgba(204, 236, 228, 1);
      border-radius: 15px;
      padding: 2rem;
      margin-right: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;

      p {
        color: rgba(80, 81, 80, 1);
        width: 83%;
        font-size: 14px;
      }

      button {
        height: 55px;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(32, 34, 42, 1);
        font-size: 15px;
        font-weight: 500;
        border-radius: 10px;
      }

      .button2 {
        background-color: rgba(15, 244, 189, 1);
        color: black;
        font-weight: 500;
      }
    }

    .content2 {
      background-color: rgba(236, 236, 238, 0.5);
      margin-right: 0 !important;
    }
  }

  .main {
    background-color: rgba(240, 240, 240, 1);
    padding: 4rem;
    border-radius: 15px;
    margin-bottom: 14vh;

    .subform {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      margin: auto;

      .ant-form-item {
        width: 100%;
      }

      .subform1 {
        width: 50%;
        margin-right: 5px;

        input {
          height: 50px;
        }


      }
    }

    .subformmsg {
      justify-content: flex-start;
      margin: 1rem auto;

      .smdisabled {
        cursor: not-allowed !important;
      }

      button,
      button:hover {
        background-color: rgba(15, 244, 189, 1);
        height: 50px;
        color: black;
        font-weight: 500;
        border: transparent;
      }
    }
  }
}

@media(max-width:1024px) {
  .containercontact .contactusmails {
    height: 32vh !important;
  }
}

@media(max-width:820px) {
  .containercontact .contactusmails {
    height: 40vh !important;
  }

  .containercontact .main {
    padding: 2rem !important;
  }
}
@media(max-width:1350px)
{
  .containercontact .contactusmails {
    height:55vh !important
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;