.select-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .center_container {
    width: 69%;
    height:80%;
    // height: 834px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 45px;
    .role_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 444px;
      height: 429px;
      gap: 40px;
    }
    .role_header {
      //
      gap: 10px;
      width: 401px;
      height: 31px;
    }
    h1 {
      color: #000;
      text-align: center;

      /* H3 */
      font-family: "Poppins",sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 153.846% */
    }
    .input_field {
      width: 25rem;
      height: 4.375rem;
      color: rgba(161, 161, 170, 0.4);
      font-family: "Poppins",sans-serif;
      font-size: 1.0625rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      border-radius: 0.9375rem;
      border: 1px solid #a1a1aa;
      background: #fff;
    }
    .p_container {
      //
      gap: 10px;
      p {
        color: var(--2, #505150);
        text-align: center;

        /* 16_Body_1 */
        font-family: "Poppins",sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
      }
    }
  }
  .role_content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    color: #000;
    text-align: center;
    font-family: "Poppins",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    .client {
      width: 212px;
      height: 174px;
      display: flex;
      padding: 20px 40px;
      flex-direction: column;
      align-items: center;
      gap: 21px;
      border-radius: 15px;
      border: 1px solid var(--light-border, #ececee);
      background: #fff;
      position: relative;
      cursor: pointer;
      .img_client {
        position: absolute;
        bottom: 85px;
      }
    }
    .selected_client {
      width: 212px;
      height: 174px;
      display: flex;
      padding: 20px 40px;
      flex-direction: column;
      align-items: center;
      gap: 21px;
      border-radius: 15px;
      border: 1px solid var(--light-border, #0ff4bd);
      background: #fff;
      position: relative;
      pointer-events: none;

      /* Color */
      box-shadow: 0px 12px 30px 0px rgba(15, 244, 189, 0.2);

      .selected_img_client {
        position: absolute;
        bottom: 85px;
      }
    }
  }

  .next_btn_disabled {
    display: flex;
    width: 400px;
    height: 60px;
    color: rgba(80, 81, 80, 0.5);
    text-align: center;
    font-family: "Poppins",sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 235.294% */
    padding: 10px 134px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #e2e2e7;
  }

  .next_btn_disabled:hover,.next_btn_disabled {
    border: #0ff4bd;
    color: #000 !important;
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;