.main_containerhome {
  width: 100%;
  height: auto;
  display: flex;
 // overflow-y: scroll;
  flex-direction: column;

  .nav_container {
    width: 100vw;
    height: 6.875rem;
    padding: 25px 60px 25px 60px;
    border: 0px 0px 1px 0px;
    justify-content: space-between;
    display: flex;
    background: #ffffff;

    img {
      width: 180px;
      height: 50px;
      flex-shrink: 0;
    }

    .nav_option {
      height: 60px;
      align-items: center;
      display: flex;
    }

    ul {
      list-style: none;
      display: flex;
      gap: 35px;
    }

    li {
      margin-top: 14px;
      color: #000;
      font-family: "Poppins",sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .signIn_btn {
      display: flex;
      width: 134px;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      border: 1px solid #0ff4bd;
      box-shadow: 0px 12px 30px 0px rgba(18, 199, 155, 0.2);
    }

    .signIn_btn:hover {
      border: 1px solid #0ff4bd;
      color: #0b0b0b;
    }

    .signUp_btn {
      display: flex;
      width: 140px;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      background: #0ff4bd;
      box-shadow: 0px 12px 30px 0px rgba(18, 199, 155, 0.2);
    }

    .signUp_btn:hover {
      border: 1px solid #0ff4bd;
      color: #0b0b0b;
    }

    .nav_btn_container {
      display: flex;
      gap: 20px;
      padding-left: 35px;
    }
  }

  .home_container {
    height: 72vh;
    width: 100vw;
    display: flex;

    .left_container {
      width: 46%;
      // height: 848px;
      flex-shrink: 0;
      border-radius: 0px 0px 0px 116px;
      background: #0b0b0b;
      box-shadow: 0px 34px 94px 0px rgba(0, 0, 0, 0.1);
      position: relative;

      .hdg_container {
        position: absolute;
        top: 7.25rem;
        left: 5.375rem;
      }

      .crt_container {
        border-left: 2px solid #fff;
        // height: 10.813rem;
        padding: 0px 0px 0px 2rem;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 19.125rem;
        left: 5.375rem;
      }

      h1 {
        display: flex;
        width: 37.5rem;
        height: 4rem;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #fff;
        font-family: "Poppins",sans-serif;
        font-size: 3.813rem;
        font-style: normal;
        font-weight: 400;
        line-height: 6.001rem;
      }

      p {
        width: 495px;
        color: #e7e7e7;
        font-family: "Poppins",sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 27.008px;
      }
    }
    .right_container {
      width: 54%;
      height: 100%;
      position: relative;
      .bgImg {
        height: 100%;
        width: 100%;
        position: absolute;
        // top: -110px;
        z-index: -1;
      }
      .grp1 {
        width: 70%;
    height: 85%;
    flex-shrink: 0;
    position: absolute;
    top: 2.875rem;
    left: 9rem;
      }
    }
  }
}

@media(max-width:1024px) {
  .nav_container {
    padding: 10px !important;

    img {
      width: 100px !important;
    }

    ul {
      gap: 17px !important;
    }

    li {
      font-size: 13px !important;
    }
  }

  .home_container {
    flex-direction: column;

    .left_container {
      width: 100% !important;
      height: 50% !important;
      border-radius: 0px !important;

      .hdg_container {
        left: 1.375rem !important;
        top: 1rem !important;
      }

      h1 {
        font-size: 3rem !important;
        height:10rem !important;
      }

      .crt_container {
        top: 13.125rem !important;
        left: 2.375rem !important;
      }

    }

    .right_container {
      width: 100% !important;
      background-color: #0b0b0b;
      img{
        object-fit: contain;
      }
    }

  }
}
@media (max-width: 800px)
{
.nav_container li {
    font-size: 11px !important;
}
}

@media (max-width:1600px)and (max-height:900px)
{
  .main_containerhome .home_container {
    height: 75vh !important;
  }
   .main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheading {
    font-size: 36px !important;
}
.main_containerhome .aboutusinfo .aboutusinfo1 .aboutusinfo1right .aboutusheadinginfo {
    line-height: 39px !important;
}

}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;