.contactus{
  position: relative;
  .img{
    width: 100%;
    img{
      width:100%;
    }
  }
  .mail{
    color: #000000;
    font-weight: 500;
    background-color: #F4F4F4;
    border-radius: 10px;
    width: 40%;
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a 
  {
    width: 30%;
    height:45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0ff4bd;
    border-radius: 15px;
    .sendmailbtn{
      color: #000000;
      font-weight: 600;
    }
  }
  // .termsandconditions{
  //   position: absolute;
  //   bottom: 1rem;
  // }

}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;